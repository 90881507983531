/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as colors from 'style/colors';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${colors.borderDefault};
  margin-top: 30px;
  .innerText {
    width: 100%;
    line-height: 40px;
    font-size: 16px;
    color: ${colors.fontSecondary};
    .leftText {
      text-align: left;
      float: left;
      clear: both;
      width: 50%;
    }
    .rightText {
      text-align: right;
      float:right;
      width: 50%;
    }
  }
`;

const InstitutionsHeader = ({ leftTextValue, rightTextValue }) => (
  <Wrapper>
    <div className="innerText">
      <span
        className="leftText"
        dangerouslySetInnerHTML={{ __html: leftTextValue }}
      />
      <span
        className="rightText"
        dangerouslySetInnerHTML={{ __html: rightTextValue }}
      />
    </div>
  </Wrapper>
);

InstitutionsHeader.propTypes = {
  leftTextValue: PropTypes.string,
  rightTextValue: PropTypes.string
};

InstitutionsHeader.defaultProps = {
  leftTextValue: '',
  rightTextValue: ''
};

export { InstitutionsHeader as default };
