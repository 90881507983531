import React, { useEffect, useState } from 'react';
import { Provider } from 'mobx-react'; // TODO: remove mobx
import { Route, Switch, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import AngularApp from './AngularApp';
import i18n from '../lib/i18next';

// Receipts
import useCognito from '../hooks/useCognito';
import './Receipts'; // initialize react component for angular usage
import ReceiptViewer from './ReceiptViewer';
// Store for
import categoriesModel from '../models/categories';
import receiptViewerModel from '../models/receiptViewer';
import receiptsModel from '../models/receipts';

import { useHealthStatus } from '../contexts/healthStatus';
import MaintenancePage from './MaintenancePage';
import ErrorPage from './ErrorPage';
import { useAuth } from '../contexts/auth';
import OnboardingInstitutionManager from './OnboardingInstitutionManager';
import useGuestInfo from '../hooks/useGuestInfo';
import useMixpanel from '../hooks/useMixpanel';
import { checkoutSessionInfo } from '../services/checkoutSessionInfo';
import { SUBSCRIPTIONS_MAP } from '../services/App/App.constants';

export default function ReactApp() {
  useMixpanel();
  const history = useHistory();
  const [appReady, setAppReady] = useState(false);

  const { accessTokenInfo } = useAuth();
  const { token: cognitoToken } = useCognito();
  const guestInfo = useGuestInfo();

  useEffect(() => {
    if (!guestInfo || !cognitoToken) {
      return;
    }

    const parsed = queryString.parse(
      window.location.search || window.location.hash
    );

    // login through myAccount, keep the last path before redirection
    let path = parsed.state && JSON.parse(parsed.state).path;

    // if there is path, redirect back the last path
    if (path) {
      const checkoutSessionId = queryString.parse(path.split('?')[1])['subscription-session']

      // we will receives subscription_session from stripe payment callback
      checkoutSessionInfo(checkoutSessionId).then((result) => {
        // enable subscription level if payment is valid according to the server
        if (result && result.guest_uid === guestInfo.uid && result.is_subscribed) {
          window.subscriptionLevel = SUBSCRIPTIONS_MAP[result.product_key]    
        }

        // remove subscription_session from the final path since we already processed it
        if (checkoutSessionId) {
          [path] = path.split('?')
        }

        if (!guestInfo.credentials.length) {
          // if the guest has institutions connected go to net-worth, else go to Onboarding
          path = '/onboarding/institutions';
        }

        history.push(path);  

        setAppReady(true);
      });
    }
  }, [guestInfo, history, cognitoToken]);
  
  useEffect(() => {
    if (accessTokenInfo) i18n.changeLanguage(accessTokenInfo.guest.lang);
  }, [accessTokenInfo]);

  const { maintenance, error } = useHealthStatus();

  if (maintenance) {
    return <MaintenancePage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    appReady && (
      <>
        <Switch>
          <Route
            path="/onboarding/institutions"
            component={OnboardingInstitutionManager}
          />
          <Route component={AngularApp} />
        </Switch>
        <Provider
          receiptViewerModel={receiptViewerModel}
          receiptsModel={receiptsModel}
          categoriesModel={categoriesModel}
        >
          <ReceiptViewer baseCurrency={guestInfo.baseCurrency} />
        </Provider>
      </>
    )
  );
}
