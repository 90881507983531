import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as colors from 'style/colors';

const Wrapper = styled.div`
  line-height: 21px;
  border: 1px solid ${colors.greyLight};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  padding: 10px 15px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  line-height: 34px;
  height: 68px;
  min-width: 1024px;
  width: 100%;
  z-index: 99;

  .innerContent {
    padding-left: 1em;
    padding-right: 1em;
    max-width: 768px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      background-color: ${colors.primary};
      margin: 5px 0px 5px 10px;
      height: 38px;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 35px;
      font-weight: 500;
      border-radius: 6px;
    }
  }
`;

const GuidanceBanner = ({ children }) => (
  <Wrapper>
    <div className="innerContent">{children}</div>
  </Wrapper>
);

GuidanceBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default GuidanceBanner;
