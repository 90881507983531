import ReceiptsGet from './ReceiptsGet';
import ReceiptsUpload from './ReceiptsUpload';
import ReceiptDelete from './ReceiptDelete';
import ReceiptRotate from './ReceiptRotate';

class Receipts {
  constructor() {
    this.get = new ReceiptsGet();
    this.uploader = new ReceiptsUpload();
    this.delete = new ReceiptDelete().delete;
    this.rotate = new ReceiptRotate().rotate;
  }
}

export default new Receipts();
