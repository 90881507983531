import { decorate, observable } from 'mobx';
import receiptsModel from 'models/receipts';

class ReceiptsUploader {
  constructor() {
    this.files = observable.map({});
  }

  set(key, progress) {
    this.files.set(key, progress || 0);

    if (progress >= 1) {
      receiptsModel.add('unmatched', key, {
        date: Date.now()
      }, true);

      this.files.delete(key);
    }
  }
}

decorate(ReceiptsUploader, {
  files: observable
});

export default new ReceiptsUploader();
