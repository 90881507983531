import { decorate, observable } from 'mobx';

import transactionService from 'services/transaction';

class Categories {
  constructor() {
    this.categories = {};
    this.currentLocale = '';
  }

  getByID(id) {
    return this.categories[id] || {};
  }

  async getCategoriesAndUpdateMap() {
    const locale = window.localStorage.getItem('mt.locale');

    if (this.currentLocale === locale) {
      return;
    }

    const categories = await transactionService.getCategories(locale);

    categories.forEach((category) => {
      this.categories[category.id] = category;
    });

    this.currentLocale = locale;
  }
}

decorate(Categories, {
  categories: observable
});


export default new Categories();
