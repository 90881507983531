/* eslint-disable react/prop-types */ // es-lint doesn't catch the prop types when using the routing HOC
import React from 'react';
import { observer } from 'mobx-react';
import { translate, Interpolate } from 'react-i18next';

import Modal from 'components/Modal';
import Institutions from 'components/Institutions/Institutions';
import { ALL_INSTITUTION_TYPES_KEY, DEFAULT_GROUPING_TYPES } from 'services/InstitutionTypes.constants';
import Tracker from 'services/Tracker/Tracker';
import { EVENT_CREATE_CREDENTIAL } from 'services/Tracker/Tracker.constants';

import GuidanceBanner from './GuidanceBanner';
import InstitutionsFilter from './InstitutionsFilter';
import InstitutionsHeader from './InstitutionsHeader';
import OnboardingWrapper from './OnboardingWrapper';
import i18n from '../../lib/i18next';

const JAPAN = 'JP';

const OnboardingInstitutions = observer(
  class OnboardingInstitutions extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        filter: {
          query: '',
          institutionType: ALL_INSTITUTION_TYPES_KEY
        }
      };
    }

    async componentDidMount() {
      try {
        document.title = i18n.t('base.pageTitle', {
          title: i18n.t('onboardingInstitutionsPage.pageTitle')
        });
      } catch (e) {
        // Due to a bug with JSDOM throwing errors inside Jest, simply
        // wrap this in a try/catch and ignore the error
      }
    }

    onContinueButtonClick = () => {
      const { preselectInstitutions } = this.props;
      const list = Object.values(preselectInstitutions);

      Tracker.track(EVENT_CREATE_CREDENTIAL, {
        'Preselected Credentials': list.map(
          i => i.fid
        ),
        'Waitlisting FI': list
          .filter(i => i.is_active === false)
          .map(i => i.fid)
      });

      const { onSubmitInstitutions } = this.props
      onSubmitInstitutions();
    };

    onInstitutionTypeClick = (institutionType) => {
      this.setState({
        filter: {
          query: '',
          institutionType
        }
      });
    };

    getInstitutionGroups() {
      const { institutions  } = this.props;
      const institutionTypes = [
        ...new Set(
          institutions.map(institution => institution.institution_type)
        )
      ];
      const institutionTypesWithGroups = institutionTypes.map(
        (institutionType) => {
          const subTypes = institutions
            .filter(
              institution => institution.institution_type === institutionType
            )
            .map(institution => ({
              label: institution.group_primary,
              key: institution.group_primary_key
            }));

          const uniqKeys = [...new Set(subTypes.map(subType => subType.key))];
          const uniqSubTypes = [];
          uniqKeys.forEach((key) => {
            const uniqSubType = subTypes.find(subType => subType.key === key);
            uniqSubTypes.push(uniqSubType);
          });
          return {
            type: institutionType,
            subTypes: uniqSubTypes
          };
        }
      );

      return institutionTypesWithGroups;
    }

    getPopularModels() {
      const { institutions: list } = this.props;

      if (this.getMostRegisteredInstitutionIds().length > 0) {
        return list.filter(institution => this.getMostRegisteredInstitutionIds().includes(institution.locale_identifier));
      }
      let endIndex = list.length - 1;
      if (list.length >= 20) {
        endIndex = 20;
      }
      return list.slice(0, endIndex);
    }

    getFilteredModels() {
      const { filter } = this.state;
      if (this.isFilterOff()) {
        return this.getPopularModels();
      }

      const { institutions } = this.props;

      return institutions
        .filter(
          institution => filter.institutionType === ALL_INSTITUTION_TYPES_KEY ||
            filter.institutionType === institution.institution_type ||
            filter.institutionType === institution.group_primary_key
        )
        .filter((institution) => {
          const filterQuery = filter.query.toLowerCase();
          return (
            institution.display_name.toLowerCase().includes(filterQuery) ||
            institution.display_name_reading
              .toLowerCase()
              .includes(filterQuery) ||
            institution.locale_identifier.toLowerCase().includes(filterQuery)
          );
        });
    }

    getMostRegisteredInstitutionIds() {
      const { country } = this.props;

      /*
        This is taken from: https://dataclips.heroku.com/xauzcidnkpsykslkpeqaxfsvwddg-Doug-top-20-institutions-by-registered-credential
        Hard coding this for the launch, but adding a TODO: for getting this dynamically generated on
        the sever side.
      */
      if (country.toUpperCase() === JAPAN) {
        return [
          'tpoint',
          'yucho_bank',
          'mufg_bank',
          'rakuten_card',
          'mizuho_bank',
          'smbc_bank',
          'ponta',
          'rakuten_super_point',
          'nanaco',
          'jcb_card',
          'amazon_point',
          'smbc_card',
          'waon',
          'saison_card',
          'ana_mileage_club',
          'aeon_card',
          'epos_card',
          'jal_mileage_bank',
          'sbi_sumishin_bank',
          'rakuten_edy'
        ];
      }
      return [];
    }

    getInstitutionGroupLabelFromKey(labelKey) {
      if (labelKey === ALL_INSTITUTION_TYPES_KEY) {
        return i18n.t('institutionTypes.ALL');
      }

      const institutionGroups = this.getInstitutionGroups();
      const institutionGroup = institutionGroups
        .reduce((acc, val) => acc.concat(val.subTypes), [])
        .find(val => val.key === labelKey);

      return institutionGroup ? institutionGroup.label : undefined;
    }

    getInstitutionHeaderText() {
      const { filter } = this.state;
      const { query, institutionType } = filter;
      let name = query;
      if (this.isFilterOff()) {
        return i18n.t('onboardingInstitutionsPage.popularAccounts');
      }
      if (institutionType !== ALL_INSTITUTION_TYPES_KEY) {
        const groupLabel = this.getInstitutionGroupLabelFromKey(
          institutionType
        );

        if (name === '') {
          name = groupLabel;
        } else {
          name = `${name} (${groupLabel})`;
        }
      }

      return i18n.t('onboardingInstitutionsPage.resultsFor', { searchQuery: name });
    }

    filterChanged = (institutionType) => {
      const { filter } = this.state;
      this.setState({
        filter: {
          query: filter.query,
          institutionType
        }
      });
    };

    isFilterOff() {
      const { filter } = this.state;
      return (
        filter.query.length === 0 &&
        filter.institutionType === ALL_INSTITUTION_TYPES_KEY
      );
    }

    searchInputChanged(query) {
      const { filter } = this.state;
      this.setState({
        filter: {
          query,
          institutionType: filter.institutionType
        }
      });
    }

    renderInstitutionList() {
      const { preselectInstitutions, onSelectInstitution } = this.props

      return (
        this.getFilteredModels().length > 0 && (
          <Institutions
            models={this.getFilteredModels()}
            preselectInstitutions={preselectInstitutions}
            onSelectHandler={onSelectInstitution}
            multiSelection
          />
        )
      );
    }

    renderGuidanceBanner() {
      const { preselectInstitutions } = this.props
      const ids = Object.keys(preselectInstitutions);
      const preselectCount = ids.length;

      if (preselectCount <= 0) {
        return null;
      }

      const displayName = preselectInstitutions[ids[0]].display_name;

      return (
        <GuidanceBanner>
          <div className="selectedInstitutions">
            {preselectCount === 1 &&
              i18n.t('onboardingInstitutionsPage.oneSelected', {
                institutionName1: displayName
              })}
            {preselectCount === 2 &&
              i18n.t('onboardingInstitutionsPage.twoOrMoreSelected', {
                institutionName1: displayName,
                numberOfSelected: preselectCount - 1
              })}
            {preselectCount >= 3 &&
              i18n.t('onboardingInstitutionsPage.threeOrMoreSelected', {
                institutionName1: displayName,
                numberOfSelected: preselectCount - 1
              })}
          </div>
          <button
            className="mt-btn mt-btn-primary continueButton"
            onClick={this.onContinueButtonClick}
          >
            {i18n.t('onboardingInstitutionsPage.continue')}
          </button>
        </GuidanceBanner>
      );
    }

    render() {
      const { filter } = this.state;
      const { preselectInstitutions } = this.props;

      return (
        <Modal
          wrapperComponent={OnboardingWrapper}
          title={i18n.t('onboardingInstitutionsPage.title')}
          subtitle={i18n.t('onboardingInstitutionsPage.subTitle')}
          controlActions={(
            <div className="controls">
              <div className="search">
                <i className="icon-search-magnifying-glass icon" />
                <input
                  onChange={event => this.searchInputChanged(event.target.value)}
                  value={filter.query}
                  type="search"
                  className="mt-input"
                  placeholder={i18n.t(
                    'onboardingInstitutionsPage.searchPlaceholder'
                  )}
                />
              </div>
              <InstitutionsFilter
                selectedInstitution={{
                  type: filter.institutionType,
                  label: this.getInstitutionGroupLabelFromKey(
                    filter.institutionType
                  )
                }}
                institutionGroups={this.getInstitutionGroups()}
                onChange={this.filterChanged}
              />
              <InstitutionsHeader
                leftTextValue={this.getInstitutionHeaderText()}
              />
            </div>
            )}
          content={(
            <div>
              <div
                className={`institutionList ${
                  Object.keys(preselectInstitutions).length > 0 ? 'isPreselected' : ''}`}
              >
                {this.renderInstitutionList()}
                {this.getFilteredModels().length === 0 && !this.isFilterOff() && (
                  <div className="text-center">
                    <h2>{i18n.t('onboardingInstitutionsPage.noResultsTitle')}</h2>
                    <div>
                      <Interpolate
                        useDangerouslySetInnerHTML
                        i18nKey="onboardingInstitutionsPage.noResultsMessage"
                        bankLink={(
                          <button
                            className="btn btn-link buttonLink"
                            onClick={() => this.onInstitutionTypeClick(DEFAULT_GROUPING_TYPES.BANK)}
                          >
                            {i18n.t('onboardingInstitutionsPage.bankLink')}
                          </button>
                          )}
                        creditCardLink={(
                          <button
                            className="btn btn-link buttonLink"
                            onClick={() => this.onInstitutionTypeClick(DEFAULT_GROUPING_TYPES.CREDIT_CARD)}
                          >
                            {i18n.t('onboardingInstitutionsPage.creditCardLink')}
                          </button>
                        )}
                        digitalMoneyLink={(
                          <button
                            className="btn btn-link buttonLink"
                            onClick={() => this.onInstitutionTypeClick(DEFAULT_GROUPING_TYPES.DIGITAL_MONEY)}
                          >
                            {i18n.t('onboardingInstitutionsPage.digitalMoneyLink')}
                          </button>
                        )}
                        pointCardLink={(
                          <button
                            className="btn btn-link buttonLink"
                            onClick={() => this.onInstitutionTypeClick(DEFAULT_GROUPING_TYPES.POINT)}
                          >
                            {i18n.t('onboardingInstitutionsPage.pointCardLink')}
                          </button>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
              {this.renderGuidanceBanner()}
            </div>
          )}
        />
      );
    }
  }
);

export default translate()(OnboardingInstitutions);
