import React from 'react';
import { observe } from 'mobx';
import { inject } from 'mobx-react';

import Receipt from '../Receipt';

import locales from './locales.json';

import './styles.css';

const ReceiptUploader = inject('receiptsUploaderModel')(
  class ReceiptUploader extends React.Component {
    constructor(props) {
      super(props);

      this.mounted = false;

      observe(props.receiptsUploaderModel.files, () => this.forceUpdate());
    }

    componentDidMount() {
      this.mounted = true;
      window.addEventListener('resize', this.updateReceiptDimension);
    }

    componentWillUnmount() {
      this.mounted = false;
      window.removeEventListener('resize', this.updateReceiptDimension);
    }

    updateReceiptDimension = () => {
      if (!this.mounted) {
        return;
      }

      window.requestAnimationFrame(() => {
        Receipt.CALCULATE_RECEIPT_DIMENSION();
        this.forceUpdate();
      });
    }

    renderReceipts(files) {
      const fileIDs = Object.keys(files);

      return fileIDs.map((receiptID) => {
        return (
          <Receipt
            key={receiptID}
            id={receiptID}
            dimension={Receipt.RECEIPT_DIMENSION}
            uploadStatus={files[receiptID]}
          />
        );
      });
    }

    render() {
      Receipt.CALCULATE_RECEIPT_DIMENSION();

      const locale = window.localStorage.getItem('mt.locale');
      const language = locale.substring(0, 2);

      // translation texts
      const translations = locales[language] || {};

      const { receiptsUploaderModel } = this.props;
      const files = receiptsUploaderModel.files.toJSON();
      const haveFiles = Object.keys(files).length > 0;

      return (
        <div className="receipt-uploader">
          {haveFiles && (<div className="receipt-uploader-text">{translations.title}</div>)}
          {this.renderReceipts(files)}
        </div>
      );
    }
  }
);

export default ReceiptUploader;
