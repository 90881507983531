import { react2angular } from 'react2angular';
import React from 'react';
import classnames from 'classnames';
import { Provider } from 'mobx-react';

import receiptsModel from 'models/receipts';
import receiptsUploaderModel from 'models/receiptsUploader';
import imageCacheModel from 'models/imageCache';

import ReceiptsContainer from './ReceiptsContainer';
import ReceiptUploadButton from './ReceiptUploadButton';

import locales from './locales.json';

import './styles.css';

const TAB_BUTTONS = [
  { type: 'left', string: 'unmatched' },
  { type: 'center', string: 'matched' },
  { type: 'right', string: 'verified' }
];

export default class Receipts extends React.Component {
  constructor(props) {
    super(props);

    this.fileSelector = undefined;

    this.state = {
      currentTabID: 0
    };
  }

  componentDidMount() {
    const { attachedImages } = this.props;
    receiptsModel.load(attachedImages);
  }

  onClickTab = tabID => this.setState({ currentTabID: tabID });

  getReceiptRef = (ref) => {
    this.receiptsRef = ref;
  };

  isButtonActive(tabID) {
    const { currentTabID } = this.state;
    return {
      'receipts-button-checked': tabID === currentTabID
    };
  }

  renderTabButtons(translations) {
    const buttonsText = translations.buttons || {};

    const buttons = TAB_BUTTONS.map((buttonInfo, index) => {
      const className = classnames(
        'receipts-button',
        `receipts-button-${buttonInfo.type}`,
        `receipts-header-${buttonInfo.string}`,
        this.isButtonActive(index)
      );

      return (
        // eslint-disable-next-line react/no-array-index-key
        <button className={className} onClick={() => this.onClickTab(index)} key={`${className}-${index}`}>
          {buttonsText[buttonInfo.string]}
        </button>
      );
    });

    return (<div className="receipts-header-buttons">{buttons}</div>);
  }

  render() {
    const locale = window.localStorage.getItem('mt.locale');
    const language = locale.substring(0, 2);

    // translation texts
    const translations = locales[language] || {};

    const { currentTabID } = this.state;
    const { showTitle, attachedImages, onImagesMarked } = this.props;
    return (
      <Provider receiptsModel={receiptsModel} receiptsUploaderModel={receiptsUploaderModel} imageCacheModel={imageCacheModel}>
        <div className="receipts" ref={this.getReceiptRef}>
          {showTitle && <div className="receipts-title">{translations.title}</div>}
          <div className="receipts-header">
            {this.renderTabButtons(translations)}
            <ReceiptUploadButton />
          </div>
          <div className="receipts-date">{}</div>
          <ReceiptsContainer
            listType={TAB_BUTTONS[currentTabID].string}
            attachedImages={attachedImages}
            onImagesMarked={onImagesMarked}
          />
        </div>
      </Provider>
    );
  }
}

// expose component to angular app
window.angular.module('mt.webapp.cloud-safe')
  .component('mtReceipts', react2angular(Receipts, ['showTitle', 'attachedImages', 'onImagesMarked']));
