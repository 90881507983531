import Cookie from 'js-cookie';
import superagent from 'superagent';
import { AUTH_API_SETTINGS } from 'services/App/App.constants';

export default class ReceiptDelete {
  delete(s3Key) {
    // get this everytime since the session/settings/token always change
    const accessToken = Cookie.get('accessToken');
    const country = 'JP';

    return new Promise((resolve, reject) => {
      const queryParam = `?attachment_key=${s3Key}`;

      superagent
        .delete(`${AUTH_API_SETTINGS[country].API_BASE_URL}/v8/api/transaction_attachments/delete.json${queryParam}`)
        .set('authorization', `Bearer ${accessToken}`)
        .set('x-api-version', 6)
        .end(async (error, response) => {
          if (error || response.status !== 204) {
            console.error(`Failed to get delete receipt: ${error}`);

            return reject();
          }

          return resolve(s3Key);
        });
    });
  }
}
