import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as colors from 'style/colors';

const Wrapper = styled.div`
  @keyframes bounce {
    0% { transform: scale(1.0); }
    50% { transform: scale(1.25); }
    100% { transform: scale(1.0); }
  }

  width: 100%;
  text-align: left;
  border-radius: 5px;
  padding: 7px 10px;
  margin-bottom: 15px;
  height: 46px !important;
  border-radius: 4px !important;
  border: 1px solid ${colors.greyLight};
  color: #2A2A2A;

  &:active, &:focus {
    color: #2A2A2A;
    border: 1px solid ${colors.greyLight};
    text-decoration: none;
  }

  &:hover {
    border: 1px solid ${colors.greyMedium};
    color: #2A2A2A;
    text-decoration: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86), border 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .institutionName {
    padding-left: 12px;
    padding-right: 15px;
    font-weight: 400;
    line-height: 33px;

    &:hover {
      text-decoration: none;
    }
  }

  i {
    color: ${colors.greyLight};

    &:hover {
      text-decoration: none;
    }
    &.checkMark {
      font-size: 24px;
      line-height: 31px;
    }
  }

  &.institutionSelected {

    i {
      color: ${colors.primary};
    }

    &.onEnter {
      i {
        &.checkMark {
          animation: bounce 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transform: scale(1.0);
        }
      }
    }

  }
  .institutionLogo {
    width: 30px;
    height: 30px;
    border: 1px solid ${colors.greyLight};
    border-radius: 3px;
    padding: 2px;
  }
`;

class Institution extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.state = {
      className: 'btn-link institution'
    };
  }

  onClickHandler() {
    const { onSelectHandler, isActive, model } = this.props;
    const { className } = this.state;

    if (onSelectHandler) {
      this.setState({
        className: `${className} onEnter`
      });
      onSelectHandler({
        isActive: !isActive,
        model
      });
    }
  }

  render() {
    const { multiSelection, isActive, model } = this.props;
    const { className } = this.state;

    return (
      <Wrapper
        className={`${className} ${isActive ? 'institutionSelected' : ''}`}
        onClick={this.onClickHandler}
      >
        <img src={model.logo_image_url} alt={model.display_name} className="pull-left institutionLogo" />
        <span className="institutionName">{ model.display_name }</span>
        { multiSelection && <i className={`pull-right ${isActive === true ? 'icon-checkmark-selected' : 'icon-checkmark-unselected'} checkMark`} /> }
      </Wrapper>
    );
  }
}

Institution.propTypes = {
  model: PropTypes.shape({
    logo_image_url: PropTypes.string,
    display_name: PropTypes.string
  }),
  multiSelection: PropTypes.bool,
  isActive: PropTypes.bool,
  onSelectHandler: PropTypes.func
};

Institution.defaultProps = {
  model: {},
  multiSelection: false,
  isActive: false,
  onSelectHandler: undefined
};

export default Institution;
