import React from 'react';
import { observer } from 'mobx-react';

import receiptsService from 'services/receipts';

import receiptViewerModel from 'models/receiptViewer';
import receiptsModel from 'models/receipts';

import ImageView from './ImageView';
import Overlay from './Overlay';
import ImagesStripe from './ImagesStripe';
import ActionButtons from './ActionButtons';

import './styles.css';

export default (
  // listen to receipts updates
  observer(
    class ReceiptViewer extends React.Component {
      constructor(props) {
        super(props);
        this.triggerRotateTimeout = 0;
      }

      clickPrev = () => receiptViewerModel.prev();

      clickNext = () => receiptViewerModel.next();

      onRotate = () => {
        window.clearTimeout(this.triggerRotateTimeout);

        const { degree, imageID } = receiptViewerModel;
        receiptsModel.setDegree(imageID, (degree + 90) % 360);

        // TODO: [RECEIPTS] this update in the BE is not working
        // wait for sometime to update the server.
        // E.g: click rotate twice will be rotate 180 instead of 90, so send 180 degree to server to avoid multiple call,
        this.triggerRotateTimeout = window.setTimeout(() => receiptsService.rotate(imageID.split('/')[1], degree), 500);
      }

      renderPrevButton() {
        return receiptViewerModel.canGoPrev() && (
          <div
            className="receipt-viewer-image-button receipt-viewer-image-button-prev"
            onClick={this.clickPrev}
          >
            <div className="receipt-viewer-image-button-icon receipt-viewer-image-button-icon-prev" />
          </div>
        );
      }

      renderNextButton() {
        return receiptViewerModel.canGoNext() && (
          <div
            className="receipt-viewer-image-button receipt-viewer-image-button-next"
            onClick={this.clickNext}
          >
            <div className="receipt-viewer-image-button-icon receipt-viewer-image-button-icon-next" />
          </div>
        );
      }

      render() {
        if (!receiptViewerModel.isOpen) {
          return (<div />);
        }

        const { baseCurrency } = this.props;

        return (
          <div className="receipt-viewer">
            <Overlay />
            <div className="receipt-viewer-image-area">
              {this.renderPrevButton()}
              <ImageView baseCurrency={baseCurrency} />
              {this.renderNextButton()}
            </div>
            <ActionButtons onRotate={this.onRotate} />
            <ImagesStripe />
          </div>
        );
      }
    }
  )
);
