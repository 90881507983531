import Cookie from 'js-cookie';
import superagent from 'superagent';

import { AUTH_API_SETTINGS, APP_SETTINGS } from 'services/App/App.constants';

export function checkoutSessionInfo(checkoutSessionId) {
  return new Promise((resolve) => {
    if (!checkoutSessionId) {
      resolve();
      return;
    }

    const accessToken = Cookie.get('accessToken');

    superagent
      .post(`${AUTH_API_SETTINGS.myAccount}/subscriptions/stripe/checkout-session-info.json`)
      .send({ 
        client_id: APP_SETTINGS.CLIENT_ID,
        checkout_session_id: checkoutSessionId 
      })
      .set('accept', 'json')
      .set('Authorization', `Bearer ${accessToken}`)
      .withCredentials()
      .end((error, response) => {
        if (error || response.status !== 200) {
          resolve();
          return;
        }

        const { body } = response;
        resolve(body);
      });
  });
}

export default { checkoutSessionInfo }
