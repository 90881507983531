import React from 'react';
import PropTypes from 'prop-types';

import Institution from './Institution';

class Institutions extends React.PureComponent {
  render() {
    const {
      models,
      preselectInstitutions,
      multiSelection,
      onSelectHandler
    } = this.props;

    return (
      <div>
        { models.map(institution => (
          <Institution
            key={institution.id}
            model={institution}
            isActive={!!preselectInstitutions[institution.id]}
            multiSelection={multiSelection}
            onSelectHandler={onSelectHandler}
          />
        ))}
      </div>
    );
  }
}

Institutions.propTypes = {
  models: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiSelection: PropTypes.bool,
  preselectInstitutions: PropTypes.object,
  onSelectHandler: PropTypes.func.isRequired
};

Institutions.defaultProps = {
  multiSelection: false,
  preselectInstitutions: {}
};


export default Institutions;
