import React from 'react';
import classnames from 'classnames';
import Spinner from 'components/Spinner';
import PropsTypes from 'prop-types';

import { Receipts } from 'models/receipts';
import transactionsModel from 'models/transactions';
import categoriesModel from 'models/categories';

import locales from './locales.json';

import './styles.css';

const TransactionInfo = class TransactionInfo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      transaction: {}
    };
  }

  async componentDidMount() {
    const { id } = this.state;
    const { transactionID } = this.props;

    if (!transactionID || transactionID === id) {
      return;
    }

    const [transaction] = await Promise.all([
      transactionsModel.get(transactionID),
      categoriesModel.getCategoriesAndUpdateMap()
    ]);

    this.setState({ transaction });
  }

  getExpenseTypeString(expenseType) {
    if (expenseType === 1) {
      return 'personal';
    }
    if (expenseType === 2) {
      return 'work';
    }

    return '';
  }

  getPriceString(amount) {
    const { baseCurrency } = this.props;
    const currencySymbol = baseCurrency === 'JPY' ? '¥' : '$';
    let string = `${currencySymbol}${amount.toLocaleString()}`;

    if (amount < 0) {
      string = `-${currencySymbol}${amount.toLocaleString().substr(1)}`;
    }

    return string;
  }

  renderContent(transaction) {
    const {
      amount,
      description_guest: descriptionGuest,
      date,
      category_id: categoryId,
      expense_type: expenseType
    } = transaction;

    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth();
    const day = dateObject.getDate();
    const locale = window.localStorage.getItem('mt.locale');
    const dateString = Receipts.GET_YEAR_MONTH_STRING(locale, year, month, day);

    const language = locale.substring(0, 2);
    const translations = locales[language] || {};

    const expenseString = this.getExpenseTypeString(expenseType);

    const expenseTypeClassName = `receipt-viewer-transaction-info-${expenseString}`;

    return (
      date && (
        <>
          <div className="receipt-viewer-transaction-info-left">
            <div className="receipt-viewer-transaction-info-date">{dateString}</div>
            <div className="receipt-viewer-transaction-info-category">{categoriesModel.getByID(categoryId).name}</div>
            <div className="receipt-viewer-transaction-info-description">{descriptionGuest || '-'}</div>
          </div>
          <div className="receipt-viewer-transaction-info-right">
            <div className={classnames('receipt-viewer-transaction-info-expense-type', expenseTypeClassName)}>
              {translations[expenseString]}
            </div>
            <div className="receipt-viewer-transaction-info-amount">{this.getPriceString(amount)}</div>
          </div>
        </>
      )
    );
  }

  render() {
    const { transaction } = this.state;

    return (
      <div className="receipt-viewer-transaction-info">
        {!transaction.id && <Spinner className="receipt-viewer-transaction-info-spinner" />}
        {transaction && this.renderContent(transaction)}
      </div>
    );
  }
};

TransactionInfo.propsTypes = {
  transactionID: PropsTypes.string.isRequired
};

export default TransactionInfo;
