
// Moneytree's primary brand color (green)
const primary = '#20C51B';
const error = '#F1AE3F';

const greyLight = '#e7e7e7';
const greyUltraLight = '#f4f4f4';
const greyMedium = '#d7d7d7';
const greyMediumDarker = '#8c8c8c';

const borderDefault = '#BEC2BE';
const borderFocused = '#16B831';

const fontSecondary = '#919491';

export {
  primary,
  error,

  greyLight,
  greyUltraLight,
  greyMedium,
  greyMediumDarker,

  fontSecondary,

  borderDefault,
  borderFocused
};
