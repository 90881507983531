import { transaction, action, decorate, observable } from 'mobx';
import receiptViewerModel from 'models/receiptViewer';
import receiptsService from 'services/receipts';
import imageCacheModel from 'models/imageCache';

const COUNT_PER_PAGE = 25;

export class Receipts {
  static GET_YEAR_MONTH_STRING(locale, year, month, day) {
    let dateYearString;

    const [lang] = locale.split('_');

    switch (lang) {
      case 'ja': {
        dateYearString = `${year}年${parseInt(month, 10) + 1}月`;

        if (day) {
          dateYearString += `${day}日`;
        }
        break;
      }
      default: {
        const monthString = new Date(year, month).toLocaleString(lang, { month: 'long' });
        dateYearString = `${monthString} ${year}`;

        if (day) {
          dateYearString = `${day} ${dateYearString}`;
        }
        break;
      }
    }

    return dateYearString;
  }

  constructor() {
    this.page = 0;

    this.receipts = observable({});
    this.unmatched = observable([]);
    this.matched = observable([]);
    this.verified = observable([]);
    // list of receipts attached to a specific transaction. it will be computed when we load a transacion.
    this.attached = observable([]);
  }

  async load(attachedImages) {
    this.page += 1;
    const result = await receiptsService.get.list(this.page);
    this.setAttachedImages(attachedImages);
    return result;
  }

  sort(listType) {
    const list = this[listType];

    if (!list) {
      return;
    }

    const { receipts } = this;
    const newList = list.slice().sort((a, b) => (receipts[a].date > receipts[b].date ? -1 : 1));

    list.replace(newList);
  }

  moveReceiptToNewListOnStatusChange(receipt, newStatus) {
    // skip no receipt or no status change
    if (!receipt || receipt.status === newStatus) {
      return;
    }

    // status changed, update list
    const id = receipt;
    const oldIndex = this[receipt.status].indexOf(id);
    const newIndex = this[newStatus].indexOf(id);

    // remove receipt from old list if exist
    if (oldIndex !== -1) {
      this[receipt.status].splice(oldIndex, 1);
    }

    // push receipt to new list if not exists
    if (!newIndex) {
      this[newStatus].push(id);
    }

    // update receipt status
    receipt.status = newStatus;
  }

  add(state, s3Key, { imageID, date, createdAt, transactionID }, isInverse) {
    // thumbnail key generation
    let thumbnailKey = s3Key.split('.');
    thumbnailKey.pop();
    thumbnailKey = `${thumbnailKey.join('.')}.png`;
    thumbnailKey = thumbnailKey.split('/');
    thumbnailKey = `${thumbnailKey[0]}/thumbnail/${thumbnailKey[1]}`;

    // use s3 key as the map id for reference
    this.receipts[s3Key] = {
      imageID,
      transactionID,
      thumbnailKey,
      status: state,
      degree: imageCacheModel.getCacheDegree(s3Key) || 0,
      date: new Date(date),
      createdAt: new Date(createdAt)
    };

    // add new receipts to appropriate status list, this will trigger the update
    // isInverse true will add the recipt to the beginning of the list
    if (isInverse) {
      this[state].unshift(s3Key);
      return;
    }

    this[state].push(s3Key);
  }

  swap(key1, key2) {
    const list = ['unmatched', 'matched', 'verified'];

    for (let i = 0; i < list.length; i += 1) {
      const listType = list[i];
      const index = this[listType].indexOf(key1);

      if (index === -1) {
        continue;
      }

      for (let j = 0; j < list.length; j += 1) {
        const listType2 = list[j];
        const index2 = this[listType2].indexOf(key2);

        if (index2 !== -1) {
          this[listType].splice(index, 1);
          this[listType2].splice(index2, 1);
          this[listType].push(key2);
          this[listType2].push(key1);

          this.sort(listType);
          this.sort(listType2);

          return;
        }
      }
    }
  }

  move(imageID, fromListType, toListType) {
    const fromList = this[fromListType];
    const toList = this[toListType];

    const fromIndex = fromList.indexOf(imageID);

    if (fromIndex === -1) {
      return;
    }

    fromList.splice(fromIndex, 1);
    toList.push(imageID);

    this.sort(fromListType);
    this.sort(toListType);
  }

  async delete(receiptID) {
    await receiptsService.delete(receiptID);

    let index = this.unmatched.indexOf(receiptID);

    if (index !== -1) {
      this.unmatched.splice(index, 1);
    }

    index = this.matched.indexOf(receiptID);

    if (index !== -1) {
      this.matched.splice(index, 1);
    }

    index = this.verified.indexOf(receiptID);

    if (index !== -1) {
      this.verified.splice(index, 1);
    }

    delete this.receipts[receiptID];

    receiptViewerModel.updateCurrentIndex();
  }

  updateContent(receipts) {
    if (receipts.length < COUNT_PER_PAGE) {
      this.page -= 1;
    }

    return new Promise((resolve) => {
      transaction(() => {
        receipts.forEach(({ id, s3_key: s3Key, state, created_at: createdAt, attachments }) => {
          const receipt = this.receipts[s3Key];

          // move receipt around based on status change if exists
          if (receipt) {
            this.moveReceiptToNewListOnStatusChange(receipt, state);
            return;
          }

          let transactionDate = createdAt;
          let transactionID = 0;

          if (attachments && attachments.length) {
            transactionDate = attachments[0].date;
            transactionID = attachments[0].transaction_id;
          }

          this.add(state, s3Key, {
            imageID: id,
            transactionID,
            date: transactionDate,
            createdAt
          });
        });

        this.sort('unmatched');
        this.sort('matched');
        this.sort('verified');

        return resolve(receipts.length === COUNT_PER_PAGE);
      });
    });
  }

  getMonthsToReceiptIDsMap(listType) {
    const monthsToReceiptsMap = {};

    if (!this[listType] && !Array.isArray(this[listType])) {
      throw new Error(`invalidReceiptsListType:${listType}`);
    }

    this[listType].forEach((receiptID) => {
      const receipt = this.receipts[receiptID];

      if (!receipt) {
        return;
      }

      const month = receipt.date.getMonth();
      const year = receipt.date.getFullYear();
      // custom ID to separate data into month and year. E.g: 2018 Sep -> 201809 or 2018 Oct -> 201810
      const dateID = `${year}${month < 10 ? `0${month}` : month}`;
      monthsToReceiptsMap[dateID] = monthsToReceiptsMap[dateID] || [];

      if (monthsToReceiptsMap[dateID].indexOf(receiptID) === -1) {
        monthsToReceiptsMap[dateID].push(receiptID);
      }
    });

    return monthsToReceiptsMap;
  }

  getListInfoByType(listType) {
    const locale = window.localStorage.getItem('mt.locale');
    const list = this.getMonthsToReceiptIDsMap(listType);
    const sortedList = Object.keys(list).sort((a, b) => b - a);

    return sortedList.map((monthYearID) => {
      const year = monthYearID.toString().substr(0, 4);
      const month = monthYearID.toString().substr(4, 2);

      return {
        dateYear: Receipts.GET_YEAR_MONTH_STRING(locale, year, month),
        list: list[monthYearID]
      };
    });
  }

  getByID(receiptID) {
    return this.receipts[receiptID];
  }

  async canUpload() {
    const injector = window.angular.element(document.body.querySelector('*[ng-app]')).injector();
    const payWallService = injector.get('PayWallService');

    try {
      const metadata = {
        entryPoint: 'receipts',
        feature: 'cloudSafe'
      }
      await payWallService.setMetaDataObject(metadata).isPremiumGuest();

      return true;
    } catch (error) {
      return false;
    }
  }

  setDegree(receiptID, degree) {
    this.receipts[receiptID].degree = degree;
    imageCacheModel.setCacheDegree(receiptID, degree);
  }

  setDate(receiptID, date) {
    // date is javascript date object
    this.receipts[receiptID].date = date;
  }

  setAttachedImages(attachedImages) {
    if (!attachedImages) {
      return;
    }
    this.attached = attachedImages.map(image => image.s3_key);
  }
}

decorate(Receipts, {
  add: action,
  swap: action,
  sort: action,
  setDegree: action,
  setDate: action,
  setAttachedImages: action
});

export default new Receipts();
