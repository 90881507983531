import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { APP_SETTINGS, MIXPANEL_SETTINGS } from 'services/App/App.constants';
import eventMapping from '@moneytree/mobile-tools';
import useGuestInfo from './useGuestInfo';

// Helpers for translating API subscription levels to the
// well-known public product names.
// https://github.com/moneytree/toshokan/blob/master/pfm/iap/items-and-pricing.md

const PERSONAL_FREE = 'free';
const PERSONAL_GROW = 'grow'; // API returns 'starter'

const BUSINESS_FREE = 'free';
const BUSINESS_EXPENSES = 'expenses'; // API returns 'premium'
const BUSINESS_CORPORATE = 'corporate'; // API returns 'professional'

function translatePersonalPlan(apiName) {
  switch (apiName) {
    case 'starter':
      return PERSONAL_GROW;
    default:
      return PERSONAL_FREE;
  }
}

function translateBusinessPlan(apiName) {
  switch (apiName) {
    case 'premium':
      return BUSINESS_EXPENSES;
    case 'professional':
      return BUSINESS_CORPORATE;
    default:
      return BUSINESS_FREE;
  }
}

export default function useMixpanel() {
  const guestInfo = useGuestInfo();

  useEffect(() => {
    if (!guestInfo || !MIXPANEL_SETTINGS) {
      return;
    }

    const { subscriptionsLevel, uid } = guestInfo;
    const { personal, business } = subscriptionsLevel;

    mixpanel.init(MIXPANEL_SETTINGS.token, {
      debug: MIXPANEL_SETTINGS.debug || false
    });

    mixpanel.register({
      appUID: APP_SETTINGS.CLIENT_ID.slice(0, 5),
      paying: business !== 'free' || personal !== 'free',
      plan: {
        personal: translatePersonalPlan(personal),
        business: translateBusinessPlan(business)
      }
    });

    mixpanel.identify(uid);

    // eslint-disable-next-line
  }, [guestInfo?.uid]);
}

export function sendEvent(category, event, options = {}) {
  try {
    const eventKey = eventMapping[category][event].key;

    mixpanel.track(eventKey, options);
    // eslint-disable-next-line no-empty
  } catch {}
}

export function updateSubscription(newPlan, currentSubscriptions) {
  const plan = {};
  currentSubscriptions.forEach((singleSubscription) => {
    const name = singleSubscription.category;
    const level = singleSubscription.subscription_level;
    plan[name] = level;
  });

  // Web supports only business plans. Not personal
  const planMapping = {
    'jp.moneytree.professional.monthly': 'professional',
    'jp.moneytree.premium.monthly': 'premium'
  };
  plan.business = translateBusinessPlan(planMapping[newPlan.id] || 'free');
  plan.personal = translatePersonalPlan(plan.personal);

  const paying = plan.business !== 'free' || plan.personal !== 'free';

  // Update super properties for further events
  mixpanel.register({
    paying,
    plan
  });
  sendEvent('paywall', 'subscription_completed');
}
