export const INSTITUTION_TYPES = {
  BANK: 'bank',
  CORPORATE: 'corporate',
  POINT: 'point',
  CREDIT_CARD: 'credit_card',
  DIGITAL_MONEY: 'stored_value',
  MANUAL: 'manual',
  STOCK: 'stock'
};

export const DEFAULT_GROUPING_TYPES = {
  BANK: 'grouping_bank',
  CREDIT_CARD: 'grouping_credit_card',
  DIGITAL_MONEY: 'grouping_digital_money',
  POINT: 'grouping_point'
};

export const ALL_INSTITUTION_TYPES_KEY = 'ALL';

export default INSTITUTION_TYPES;
