/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import Spinner from 'components/Spinner';

import receiptsService from 'services/receipts';
import receiptViewerModel from 'models/receiptViewer';
import receiptsModel from 'models/receipts';

import TransactionInfo from '../TransactionInfo';
import './styles.css';

const ImageView = (
  observer(
    class ImageView extends React.Component {
      constructor(props) {
        super(props);

        this.mounted = false;

        this.imageID = undefined;
        this.isLoading = false;

        this.imageViewRef = React.createRef();
        this.imageRef = React.createRef();

        this.state = {
          base64: undefined,
          showTransactionInfo: true
        };
      }

      componentDidMount() {
        this.mounted = true;

        window.addEventListener('resize', this.resizeViewport);
      }

      componentWillUnmount() {
        this.mounted = false;

        window.removeEventListener('resize', this.resizeViewport);
      }

      componentDidUpdate() {
        const { imageID } = receiptViewerModel;
        const { showTransactionInfo } = this.state;

        if (this.imageID !== imageID && !showTransactionInfo) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ showTransactionInfo: true });
        }

        this.resizeViewport();
      }

      resizeViewport = () => window.requestAnimationFrame(() => {
        const imageViewRef = this.imageViewRef.current;
        const imageRef = this.imageRef.current;

        if (!imageViewRef || !imageRef) {
          return;
        }

        const { offsetWidth, offsetHeight } = imageViewRef;

        const { degree } = receiptViewerModel;

        let maxWidth = offsetWidth;
        let maxHeight = offsetHeight;

        if (degree === 90 || degree === 270) {
          maxWidth = offsetHeight;
          maxHeight = offsetWidth;
        }

        imageRef.style.maxWidth = `${maxWidth}px`;
        imageRef.style.maxHeight = `${maxHeight}px`;
      })

      async loadImage(imageID) {
        // do not render if no image or image is the same
        if (!imageID || this.imageID === imageID) {
          return;
        }

        this.isLoading = true;

        const base64 = await receiptsService.get.imageByID(imageID);

        this.isLoading = false;
        this.imageID = imageID;

        if (imageID !== this.imageID) {
          return;
        }

        if (this.mounted) {
          this.setState({ base64 });
        }
      }

      toggleTransactionInfo = () => {
        const { showTransactionInfo } = this.state;
        this.setState({
          showTransactionInfo: !showTransactionInfo
        });
      }

      renderContent() {
        const { base64, showTransactionInfo } = this.state;
        const { transactionID } = receiptViewerModel.receipt;
        const renderTransactionInfo = Boolean(showTransactionInfo && transactionID);
        const { degree } = receiptsModel.getByID(receiptViewerModel.imageID);
        const { baseCurrency } = this.props;

        return this.isLoading ?
          (<Spinner className="receipt-viewer-spinner" />) :
          (
            <>
              <img
                className="receipt-viewer-image-view-image"
                src={base64}
                alt="Temporary Unavailable"
                ref={this.imageRef}
                onClick={this.toggleTransactionInfo}
                style={{ transform: `translate(-50%, -50%) rotate(${degree}deg)` }}
              />
              {renderTransactionInfo && (<TransactionInfo transactionID={transactionID} baseCurrency={baseCurrency} />)}
            </>
          );
      }

      render() {
        const { imageID } = receiptViewerModel;
        this.loadImage(imageID);

        return (
          <div className="receipt-viewer-image-view" ref={this.imageViewRef}>
            {this.renderContent()}
          </div>
        );
      }
    }
  )
);

export default ImageView;
