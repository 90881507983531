import uuid from 'uuid/v4';
import awsBucket from 'configs/awsBucket.json';

import imageCacheModel from 'models/imageCache';
// eslint-disable-next-line import/no-cycle
import receiptsUploaderModel from 'models/receiptsUploader';

const ENV = process.env.REACT_APP_STAGE_ENVIRONMENT;

export default class ReceiptsUpload {
  getKeyNameByIdentityIdAndFileExtensionType(identityId, fileTypeExtension) {
    return `${identityId}/${uuid()}.${fileTypeExtension}`;
  }

  uploads(files) {
    files.forEach(file => this.upload(file));
  }

  upload(file) {
    const { identityId } = window.AWS.config.credentials;
    const keyName = this.getKeyNameByIdentityIdAndFileExtensionType(identityId, file.type.split('/')[1]);

    const params = {
      Key: keyName,
      Body: file,
      ContentType: file.type
    };

    return new Promise((resolve, reject) => {
      // save image into cache
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        imageCacheModel.add(keyName, reader.result);
        receiptsUploaderModel.set(keyName);
      };

      const country = 'JP';

      new window.AWS.S3({
        params: {
          Bucket: awsBucket[country][ENV].cloudSafeS3Bucket,
          Delimiter: 'thumbnail/', // This is the way iOS does it
          Marker: `${identityId}/`, // This is the way iOS does it
          Prefix: `${identityId}/` // This is the way iOS does it
        }
      })
        .putObject(params, (error) => {
          if (error) {
            console.error('Receipt upload put object', error);

            reject();
            return;
          }

          resolve(keyName);
        })
        .on('httpUploadProgress', (progress) => {
          receiptsUploaderModel.set(keyName, progress.loaded / progress.total);
        });
    });
  }
}
