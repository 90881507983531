import React from 'react';
import receiptViewerModel from 'models/receiptViewer';
import './styles.css';

const Overlay = (
  class Overlay extends React.PureComponent {
    closeViewer = () => {
      receiptViewerModel.close();
    }

    render() {
      return (
        <div className="receipt-viewer-overlay">
          <div className="receipt-viewer-overlay-tint" />
          <div className="receipt-viewer-overlay-close" onClick={this.closeViewer} />
        </div>
      );
    }
  }
);

export default Overlay;
