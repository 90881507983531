import Cookie from 'js-cookie';
import superagent from 'superagent';
import { AUTH_API_SETTINGS } from 'services/App/App.constants';

// eslint-disable-next-line import/no-cycle
import receiptsModel from 'models/receipts';
import imageCacheModel from 'models/imageCache';

import awsBucket from 'configs/awsBucket.json';

const ENV = process.env.REACT_APP_STAGE_ENVIRONMENT;

const REQUEST_TIME = 60 * 1000; // seconds

export default class ReceiptsGet {
  constructor() {
    this.requestStarted = false;
  }

  // https://stackoverflow.com/questions/32702431/display-images-fetched-from-s3
  static encode(data) {
    const str = data.reduce((a, b) => a + String.fromCharCode(b), '');
    return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
  }

  list(page = 1, force = false) {
    // get this everytime since the session/settings/token always change
    const accessToken = Cookie.get('accessToken');
    const country = 'JP';

    return new Promise((resolve, reject) => {
      //  skip request if previous requet is still ongoing unless force reload is enabled
      if (!force && this.requestStarted) {
        return resolve();
      }

      // setup timer
      this.requestStarted = true;

      window.setTimeout(
        () => {
          this.requestStarted = false;
        },
        REQUEST_TIME
      );

      return superagent
        .get(`${AUTH_API_SETTINGS[country].API_BASE_URL}/v8/api/web/presenter/uploads.json?page=${page}`)
        .set('authorization', `Bearer ${accessToken}`)
        .set('x-api-version', 6)
        .end(async (error, response) => {
          if (error || response.status !== 200) {
            console.error(`Failed to get congnito token info: ${error}`);

            return reject();
          }

          const { body } = response;

          const isFullPage = await receiptsModel.updateContent(body.uploads);

          this.requestStarted = false;

          return resolve(isFullPage);
        });
    });
  }

  imageByID(receiptID, isThumbnail = false) {
    let image;

    if (isThumbnail) {
      const receipt = receiptsModel.receipts[receiptID];
      // try to get local thumbnail image if available
      if (receipt && receipt.thumbnailKey) {
        image = imageCacheModel.get(receipt.thumbnailKey);
      }
    }

    // get local image if there is no thumbnail or image available
    if (!image) {
      image = imageCacheModel.get(receiptID);
    }

    // grab image from aws if image doesn't exists in local cache
    return new Promise((resolve, reject) => {
      if (image) {
        resolve(image);
        return;
      }

      const country = 'JP';

      const params = {
        Bucket: awsBucket[country][ENV].cloudSafeS3Bucket,
        Key: receiptID
      };

      new window.AWS.S3().getObject(params, (error, data) => {
        if (error) {
          console.error('Receipt get image', error);

          return reject();
        }

        const imageData = `data:image/png;base64,${ReceiptsGet.encode(data.Body)}`;
        // cache image for reuse
        imageCacheModel.add(receiptID, imageData);

        return resolve(imageData);
      });
    });
  }
}
