import { useState, useEffect } from 'react';
import { APP_SETTINGS } from 'services/App/App.constants';
import { useHealthStatus } from '../contexts/healthStatus';
import { useAuth } from '../contexts/auth';

function cacheAccountsInformationFromCredentials(credentials) {
  const guestFinancialAccountsInfo = {};

  credentials.forEach(({ accounts }) => {
    accounts.forEach(account => {
      guestFinancialAccountsInfo[account.id] = account;
    });
  });

  window.guestFinancialAccountsInfo = guestFinancialAccountsInfo;
}

export default function useGuestInfo() {
  const [guestInfo, setGuestInfo] = useState(null);

  const { accessToken, accessTokenInfo } = useAuth();
  const { setStatus } = useHealthStatus();

  useEffect(() => {
    if (!accessToken || !accessTokenInfo) {
      return;
    }

    const {
      guest: { lang },
      aud: [resourceServer]
    } = accessTokenInfo;

    fetch(
      `${resourceServer}/v8/api/web/presenter/guests/data_snapshot.json?locale=${lang}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'mt-app-name': 'mt-personal-web',
          'x-api-key': APP_SETTINGS.CLIENT_ID,
          'x-api-version': APP_SETTINGS.VERSION
        }
      }
    )
      .then(response => {
        if (response.status === 503) {
          setStatus({ maintenance: true });
          return;
        }

        if (response.status !== 200) {
          setStatus({ error: true });
          return;
        }

        response.json().then(body => {
          const { guest } = body;

          setGuestInfo({
            uid: guest.uid,
            baseCurrency: guest.base_currency,
            credentials: guest.credentials,
            subscriptionsLevel: {
              business: guest.subscriptions[0].subscription_level,
              personal: guest.subscriptions[1].subscription_level
            }
          });

          // since we already have accounts information here,
          // cache it so that it is usable by angular code
          cacheAccountsInformationFromCredentials(guest.credentials);
        });
      })
      .catch(error => {
        console.error(error);

        setStatus({ error: true });
      });
  }, [accessToken, accessTokenInfo, setStatus]);

  return guestInfo;
}
