import React from 'react';
import styled from 'styled-components';

import i18n from '../lib/i18next';
import { login } from '../contexts/auth';
import Button from './Button';

const Container = styled.div`
  margin: 60px auto;
  max-width: 724px;
  padding: 0 10px;
  justify-content: space-around;
  flex: 1;
  text-align: center;
`;

const Title = styled.h1`
  padding-bottom: 10px;
  color: #20c51b;
`;

const Img = styled.img`
  max-width: 250px;
  max-height: 250px;
`;

const RetryButton = styled(Button)`
  display: block;
  margin: 28px auto;
`

export default function ErrorPage() {
  return (
    <Container>
      <Img
        src="https://assets.moneytree.jp/link/maintenance-chibi.gif"
        width="60%"
        alt="logo"
      />
      <Title>{i18n.t('ErrorPage.title')}</Title>
      <p>
        {i18n.t('ErrorPage.description-1')}
        <br />
        {i18n.t('ErrorPage.description-2')}
        <RetryButton onClick={login}>{i18n.t('common.retry')}</RetryButton>
      </p>
    </Container>
  );
}
