import Cookie from 'js-cookie';
import superagent from 'superagent';

import { AUTH_API_SETTINGS } from 'services/App/App.constants';

class Transaction {
  getInfo(transactionID) {
    const accessToken = Cookie.get('accessToken');
    const country = 'JP';

    return new Promise((resolve, reject) => {
      superagent
        .get(`${AUTH_API_SETTINGS[country].API_BASE_URL}/v8/api/transactions/${transactionID}.json`)
        .set('Authorization', `Bearer ${accessToken}`)
        .end((error, response) => {
          if (error || response.status !== 200) {
            console.error(`Failed to get transaction info: ${error}`);

            reject();
            return;
          }

          const { body } = response;
          resolve(body.transaction);
        });
    });
  }

  getCategories(locale) {
    const accessToken = Cookie.get('accessToken');
    const country = 'JP';

    return new Promise((resolve, reject) => {
      superagent
        .get(`${AUTH_API_SETTINGS[country].API_BASE_URL}/v8/api/presenter/categories.json?locale=${locale}`)
        .set('Authorization', `Bearer ${accessToken}`)
        .end((error, response) => {
          if (error || response.status !== 200) {
            console.error(`Failed to get categories: ${error}`);

            reject();
            return;
          }

          const { body } = response;
          resolve(body.categories);
        });
    });
  }
}

export default new Transaction();
