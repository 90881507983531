import Cookie from 'js-cookie';
import moment from 'moment';
import superagent from 'superagent';
import { AUTH_API_SETTINGS } from 'services/App/App.constants';

const balanceTypes = {
  // This value means the balance represents a total amount, the whole balance.
  0: 'BALANCE_TYPE_TOTAL',

  // This value means the balance only represents the "unclosed" portion of a credit card.
  // Use of this type will fail if the associated account is not a credit card.
  1: 'BALANCE_TYPE_UNCLOSED',

  // This value means the balance only represents the "closed" portion of a credit card.
  // Use of this type will fail if the associated account is not a credit card.
  2: 'BALANCE_TYPE_CLOSED',

  // This value means the balance only represents revolving balance portion of a credit card.
  // Use of this type will fail if the associated account is not a credit card.
  3: 'BALANCE_TYPE_REVOLVING',

  // This value means the available balance of a credit card or bank account.
  // In the case of a credit card, it should mean your available credit line.
  // On a bank account, it can mean something similar - if you have 0 yen in your savings account
  // but 1M JPY in your teiki, sometimes you can withdraw up to ~80% of it as an "available" credit line.
  4: 'BALANCE_TYPE_AVAILABLE'
};

function getAccountBalanceByAccount({ account, date = moment().format('YYYY-MM-DD') }) {
  const accessToken = Cookie.get('accessToken');
  const country = 'JP';

  if (!account) {
    return Promise.reject();
  }

  return superagent
    .get(`${AUTH_API_SETTINGS[country].API_BASE_URL}/v8/api/accounts/${account.id}/account_balance.json`)
    .set('Authorization', `Bearer ${accessToken}`)
    .query({
      date
    })
    .then((response) => {
      const json = JSON.parse(response.text)
        .map((balance) => {
          balance.balance_type_key = balanceTypes[balance.balance_type];

          return balance;
        });

      return json;
    });
}

const balancesService = {
  getAccountBalanceByAccount,
  balanceTypes
};

export default balancesService;
