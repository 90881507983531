import React from 'react';
import PropsTypes from 'prop-types';

import imageCacheModel from 'models/imageCache';
import receiptViewerModel from 'models/receiptViewer';
import receiptsModel from 'models/receipts';
import locales from './locales.json';

import './styles.css';

const ActionButtons = (
  class ActionButtons extends React.Component {
    constructor(props) {
      super(props);

      this.angular = null;
    }

    componentDidMount() {
      this.angular = window.angular.element(document.body.querySelector('*[ng-app]')).injector();
    }

    delete = () => {
      const locale = window.localStorage.getItem('mt.locale');
      const language = locale.substring(0, 2);
      const translations = locales[language] || {};

      // eslint-disable-next-line no-alert
      const result = window.confirm(translations.deleteConfirmation);

      if (!result) {
        return;
      }

      receiptsModel.delete(receiptViewerModel.imageID);
    }

    attach = async () => {
      // this section of code are from angular to avoid major refactoring on the root level
      const transactionSearchService = this.angular.get('TransactionSearchService');
      const transaction = await transactionSearchService.open();

      if (!transaction) {
        return;
      }

      const TransactionFactory = this.angular.get('TransactionFactory');

      const service = new TransactionFactory()
        .setTransactionDateParam(transaction.date)
        .setTransactionIdParam(transaction.id)
        .setTransactionAmountParam(transaction.amount)
        .setTransactionCategoryIdParam(transaction.category_id)
        .setTransactionDescriptionParam(transaction.description)
        .setTransactionClaimIdParam(transaction.claim_id);

      if (transaction.expense_type === 1) {
        service.setTransactionPersonalExpenseTypeParam();
      } else {
        service.setTransactionWorkExpenseTypeParam();
      }

      const newS3Key = receiptViewerModel.imageID;
      const { imageID } = receiptsModel.receipts[newS3Key];

      const transactionData = await service.setUploadIdsParam([imageID]).update();
      const oldS3Key = transaction.attachments.length && transaction.attachments[0].attachment_key;

      if (oldS3Key) {
        receiptsModel.setDate(oldS3Key, receiptsModel.getByID(oldS3Key).createdAt);
        receiptsModel.move(oldS3Key, 'verified', 'unmatched');
      }

      receiptsModel.setDate(newS3Key, new Date(transactionData.transaction.date));
      if (receiptsModel.unmatched.length === 1) {
        receiptViewerModel.close();
      }
      receiptsModel.move(newS3Key, 'unmatched', 'verified');
    }

    addNew = async () => {
      const { imageID } = receiptViewerModel;
      const receipt = receiptsModel.getByID(imageID);

      const imageCache = imageCacheModel.get(imageID);

      // method and logic from angular mt-web-modules
      const cashTransactionModalActionService = this.angular.get('CashTransactionModalActionService');
      const transactionData = await cashTransactionModalActionService.openCreateByFile({
        id: receipt.imageID, // this image id is the actual image ID instead of the s3_key
        ...receipt,
        formatted: {
          url: imageCache,
          thumbnailUrl: imageCacheModel.get(receipt.thumbnailKey) || imageCache
        }
      }, false);

      if (transactionData) {
        receiptsModel.setDate(imageID, new Date(transactionData.transaction.date));
        if (receiptsModel.unmatched.length === 1) {
          receiptViewerModel.close();
        }
        receiptsModel.move(imageID, 'unmatched', 'verified');
      }
    }

    render() {
      // const { onRotate } = this.props;
      const receipt = receiptsModel.getByID(receiptViewerModel.imageID);
      return (
        <div className="action-buttons">
          <button
            className="action-buttons-button action-buttons-delete"
            onClick={this.delete}
          />
          {/* TODO: [RECEIPTS] disable rotation until fix the update in the BE */}
          {/* <button
            className="action-buttons-button action-buttons-rotate"
            onClick={onRotate}
          /> */}
          {!receipt.transactionID &&
          (
            <>
              <button
                className="action-buttons-button action-buttons-attach"
                onClick={this.attach}
              />
              <button
                className="action-buttons-button action-buttons-new"
                onClick={this.addNew}
                disabled={receipt.transactionID}
              />
            </>
          )}
        </div>
      );
    }
  }
);

export default ActionButtons;

ActionButtons.defaultProps = {
  onRotate: () => undefined
};

ActionButtons.propsTypes = {
  onRotate: PropsTypes.func
};
