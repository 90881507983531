import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as colors from 'style/colors';

const wrapperMaxWidth = '768';

const DefaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  font-size: 16px;
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 1024px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: white;
  align-items: center;
  .modalHead {
    max-width: ${wrapperMaxWidth}px;
    padding: 1em;
    padding-top: 60px;
    width: 100%;
    h1 {
      font-size: 28px;
      font-weight: 500;
    }
    p {
      color: ${colors.fontSecondary};
      font-size: 18px;
      font-weight: 400;
    }
    .headerActions {
      button {
        color: ${colors.primary};
        border: none;
        background: none;
      }
    }
  }
  .modalControlActions {
    max-width: ${wrapperMaxWidth}px;
    width: 100%;
    padding: 0px 20px;
  }
  .modalBody {
    overflow-y: scroll;
    flex: 1;
    max-width: ${wrapperMaxWidth}px;
    width: 100%;
    padding: 0px 20px;
  }
  &.wide {
    .modalBody {
      max-width: 850px;
    }
  }
`;

const Modal = (props) => {
  const {
    wrapperComponent: WrapperComponent,
    headerActions,
    title,
    subtitle,
    content,
    controlActions
  } = props;
  return (
    <WrapperComponent>
      <header className="modalHead">
        <div className="headerActions">
          { headerActions }
        </div>
        <div className="title">
          { title && (
          <h1>
            { title }
          </h1>
          )}
        </div>
        <div className="subtitle">
          { subtitle && (
          <p>
            { subtitle }
          </p>
          )}
        </div>
      </header>
      { controlActions !== null && (
        <section className="modalControlActions">
          { controlActions }
        </section>
      )}
      <section className="modalBody">
        { content }
      </section>
    </WrapperComponent>
  );
};

Modal.propTypes = {
  headerActions: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  controlActions: PropTypes.element,
  content: PropTypes.element.isRequired,
  wrapperComponent: PropTypes.any
};

Modal.defaultProps = {
  headerActions: null,
  title: null,
  subtitle: null,
  controlActions: null,
  wrapperComponent: DefaultWrapper
};

export default Modal;

export {
  DefaultWrapper
};
