import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth, login, logout, myAccountSettings } from '../contexts/auth';
import { useHealthStatus } from '../contexts/healthStatus';
import { sendEvent, updateSubscription } from '../hooks/useMixpanel';
import receiptViewerModel from '../models/receiptViewer';
import { STRIPE_SETTINGS } from '../services/App/App.constants';

export default function AngularApp() {
  const { accessTokenInfo } = useAuth();
  const history = useHistory();
  const { setStatus } = useHealthStatus();

  useEffect(() => {
    if (!accessTokenInfo) {
      return;
    }

    // use window object to pass data into angular land
    window.react = {
      receiptViewerModel,
      goMaintenance: () => {
        setStatus({ maintenance: true });
      },
      goError: () => {
        setStatus({ error: true });
      },
      auth: {
        login,
        logout,
        tokenInfo: accessTokenInfo,
        settings: myAccountSettings
      },
      stripe: STRIPE_SETTINGS,
      mixpanel: {
        sendEvent,
        updateSubscription
      }
    };

    try {
      window.angular.bootstrap(document.getElementById('mt-webapp'), ['mt.webapp']);
    } catch (e) {
      // Due to a bug with JSDOM throwing errors inside Jest, simply
      // wrap this in a try/catch and ignore the error
    }
  }, [accessTokenInfo, setStatus]);

  useEffect(() => {
    window.react.history = history;
  }, [history]);

  return (
    <div className="app-content-wrap l-min-height--100 desktop">
      <div
        /* eslint-disable react/no-danger */
        dangerouslySetInnerHTML={{
          __html: `
              <div id="mt-webapp" ng-app="mt.webapp">
                <mt-loading-screen></mt-loading-screen>
                <section ui-view></section>
              </div>
            `
        }}
        /* eslint-enable react/no-danger */
      />
    </div>
  );
}
