import { decorate, observable, computed, action } from 'mobx';
import receiptsModel from 'models/receipts';

class ReceiptViewer {
  constructor() {
    this.isOpen = false;
    this.listType = 'unmatched';
    this.currentIndex = -1;
  }

  get imageIDs() {
    return receiptsModel[this.listType];
  }

  open(currentImageID, listType) {
    this.listType = listType;
    const index = listType ? this.imageIDs.indexOf(currentImageID) : -1;

    this.currentIndex = index === -1 ? 0 : index;

    const bodyStyle = document.body.style;
    bodyStyle.minWidth = '100%';
    bodyStyle.overflow = 'hidden';

    this.isOpen = true;
  }

  close() {
    document.body.style.minWidth = '1024px';

    this.isOpen = false;
  }

  canGoNext(count = 1) {
    const nextIndex = this.currentIndex + count;

    return this.imageIDs.length > 1 && nextIndex <= this.imageIDs.length - 1;
  }

  canGoPrev() {
    const prevIndex = this.currentIndex - 1;

    return this.imageIDs.length > 1 && prevIndex >= 0;
  }

  next() {
    if (this.canGoNext()) {
      this.currentIndex += 1;
    }
  }

  prev() {
    if (this.canGoPrev()) {
      this.currentIndex -= 1;
    }
  }

  get imageID() {
    return this.imageIDs[this.currentIndex];
  }

  set imageID(value) {
    this.currentIndex = this.imageIDs.indexOf(value);
  }

  get degree() {
    return this.receipt.degree;
  }

  get receipt() {
    return receiptsModel.getByID(this.imageID);
  }

  updateCurrentIndex() {
    const list = this.imageIDs;

    // close viewer when there are no images
    if (!list.length) {
      this.currentIndex = 0;
      this.close();
      return;
    }

    // move currentIndex if out of list range
    if (this.currentIndex >= list.length) {
      this.currentIndex = list.length - 1;
    }
  }
}

decorate(ReceiptViewer, {
  isOpen: observable,
  currentIndex: observable,
  imageIDs: computed,
  receipt: computed,
  degree: computed,
  updateCurrentIndex: action
});


export default new ReceiptViewer();
