/* eslint-disable import/prefer-default-export */
const EVENT_SIGNUP = '$signup';
const EVENT_SIGNIN = 'Logged In';
const EVENT_CREATE_CREDENTIAL = 'Created Credential';

const SUBSCRIPTION_PLAN_NAMES_SHORT_FOR_SUBSCRIPTION_LEVEL = {
  free: 'Free',
  premium: 'PLUS',
  professional: 'PRO'
};

export {
  EVENT_SIGNUP,
  EVENT_SIGNIN,
  EVENT_CREATE_CREDENTIAL,

  SUBSCRIPTION_PLAN_NAMES_SHORT_FOR_SUBSCRIPTION_LEVEL
};
