import { CLOUD_SAFE_SETTINGS } from '../App/App.constants';

export default class ReceiptRotate {
  rotate(s3Key, degree) {
    const params = {
      FunctionName: CLOUD_SAFE_SETTINGS.rotateImageFunction,
      Payload: JSON.stringify({
        s3_key: s3Key,
        degree
      })
    };

    return new Promise((resolve, reject) => {
      new window.AWS.Lambda().invoke(params, (error, data) => {
        if (error) {
          console.error('Recipt rotate lambda', error);

          return reject();
        }

        return resolve(data);
      });
    });
  }
}
