import React from 'react';
import { inject } from 'mobx-react';
import receiptsService from 'services/receipts';
import { sendEvent } from 'hooks/useMixpanel';

import locales from './locales.json';

import './styles.css';

const UPLOAD_SIZE_LIMIT = 5 * 1000 * 1000; // bytes

const ReceiptUploadButton = inject('receiptsModel')(
  class ReceiptUploadButton extends React.Component {
    constructor(props) {
      super(props);

      this.fileSelector = undefined;
    }

    validateFiles = () => {
      const { files } = this.fileSelector;
      const validFiles = [];

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];

        if (file.size <= UPLOAD_SIZE_LIMIT) {
          validFiles.push(file);
        }
      }

      if (validFiles.length) {
        sendEvent('receipts', 'receipt_uploaded', { fileSource: 'photoLibrary' });
        receiptsService.uploader.uploads(validFiles);
      }
    };

    getUploadFileInput = (ref) => {
      this.fileSelector = ref;
    };

    upload = async () => {
      const { receiptsModel } = this.props;
      const canUpload = await receiptsModel.canUpload();

      if (!canUpload) {
        return;
      }

      // select files to upload
      this.fileSelector.click();
    };

    render() {
      const locale = window.localStorage.getItem('mt.locale');
      const language = locale.substring(0, 2);

      // translation texts
      const translations = locales[language] || {};

      return (
        <>
          <input
            className="receipt-upload-button-input"
            type="file"
            ref={this.getUploadFileInput}
            multiple={true}
            accept="image/*"
            onChange={this.validateFiles}
          />
          <button className="receipt-upload-button" onClick={this.upload}>
            <span className="receipt-upload-button-icon icon-plus" />
            <span className="receipt-upload-button-text">{translations.addFiles}</span>
          </button>
        </>
      );
    }
  }
);

export default ReceiptUploadButton;
