import { APP_SETTINGS } from 'services/App/App.constants';
import awsBucketConfig from 'configs/awsBucket.json';
import awsCognitoConfig from 'configs/awsCognito.json';
import { useState, useEffect } from 'react';
import { useHealthStatus } from '../contexts/healthStatus';
import { useAuth } from '../contexts/auth';

const ENV = process.env.REACT_APP_STAGE_ENVIRONMENT;

export default function useCognito() {
  const { accessToken, accessTokenInfo } = useAuth();
  const { setStatus } = useHealthStatus();

  const [cognitoToken, setCongnitoToken] = useState({
    identityId: null,
    token: null
  });

  useEffect(() => {
    if (!accessToken || !accessTokenInfo) {
      return;
    }

    const {
      guest: { country },
      aud: [resourceServer]
    } = accessTokenInfo;

    fetch(`${resourceServer}/v8/api/guests/cognito_token.json`, {
      method: 'GET',
      headers: {
        'mt-app-name': 'mt-personal-web',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': APP_SETTINGS.CLIENT_ID,
        'x-api-version': APP_SETTINGS.VERSION
      }
    })
      .then(response => {
        if (response.status === 503) {
          setStatus({ maintenance: true });
          return;
        }

        if (response.status !== 200) {
          setStatus({ error: true });
          return;
        }

        response.json().then(body => {
          const { identity_id: identityId, token } = body.cognito_token;

          window.mtCognitoToken = { identityId, token };

          window.AWS.config.update({
            region: awsBucketConfig[country][ENV].region,
            credentials: new window.AWS.CognitoIdentityCredentials({
              IdentityPoolId: awsCognitoConfig[ENV].identityPoolId,
              IdentityId: identityId,
              Logins: {
                'cognito-identity.amazonaws.com': token
              }
            })
          });

          setCongnitoToken(window.mtCognitoToken);
        });
      })
      .catch(error => {
        console.error(error);

        setStatus({ error: true });
      });
  }, [accessToken, accessTokenInfo, setStatus]);

  return cognitoToken;
}
