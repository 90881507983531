import i18n from 'i18next';

const LANGUAGES = {
  ENGLISH: 'en',
  JAPANESE: 'ja'
};

const resBundle = {};

Object.keys(LANGUAGES).forEach(key => {
  const region = LANGUAGES[key];
  /* eslint-disable global-require, import/no-dynamic-require */
  resBundle[region] = { mt: require(`../translations/${region}.json`) };
  /* eslint-enable */
});

export default i18n.init({
  lng: LANGUAGES.JAPANESE,
  resources: resBundle,
  fallbackLng: LANGUAGES.JAPANESE,
  ns: ['mt'],
  defaultNS: 'mt',
  joinArrays: '\n',
  debug: false
});
