/* eslint-disable no-console */

// TODO: implement new analytics system

const register = (newProperties) => {
  console.info('Track.register', newProperties);
};

const track = (event, properties = {}) => {
  console.info('Track.track', event, properties);
};

const identify = (id) => {
  console.info('Track.identify', id);
};

const alias = (id) => {
  console.info('Track.alias', id);
};

const Tracker = {
  register,
  track,
  identify,
  alias
};

export default Tracker;
