import transactionService from 'services/transaction';

const MAX_CACHE_COUNT = 200;

class Transactions {
  constructor() {
    this.transactions = {};
  }

  add(id, transaction) {
    const cacheArr = Object.keys(this.transactions);

    if (cacheArr.length > MAX_CACHE_COUNT) {
      delete this.transactions[cacheArr[0]];
    }

    this.transactions[id] = transaction;
  }

  async get(id) {
    let transaction = this.transactions[id];

    if (transaction) {
      return transaction;
    }

    // hit server to grab the transaction content
    transaction = await transactionService.getInfo(id);

    this.add(id, transaction);

    return transaction;
  }
}

export default new Transactions();
