import store from 'store';

const MAX_CACHE_COUNT = 200;

class ImageCache {
  constructor() {
    this.images = store.get('imageCache') || {};
  }

  add(id, data) {
    const cacheArr = Object.keys(this.images);

    if (cacheArr.length > MAX_CACHE_COUNT) {
      delete this.images[cacheArr[0]];
    }

    this.images[id] = data;
    store.set('imageCache', cacheArr);
  }

  get(id) {
    return this.images[id];
  }

  setCacheDegree(id, degree) {
    const degreeCache = store.get('imageDegreeCache') || {};
    degreeCache[id] = degree;
    store.set('imageDegreeCache', degreeCache);
  }

  getCacheDegree(id) {
    const degreeCache = store.get('imageDegreeCache');
    return degreeCache && degreeCache[id];
  }
}

export default new ImageCache();
