import React, { createContext, useContext, useState, useCallback } from 'react';

const defaultStatus = {
  maintenance: false,
  error: false
};

const HeathStatusContext = createContext({
  ...defaultStatus,
  setStatus: () => null
});

export function useHealthStatus() {
  return useContext(HeathStatusContext);
}

export default function(props) {
  const { children } = props;
  const [
    { maintenance: maintenanceStatus, error: errorStatus },
    setStatus
  ] = useState(defaultStatus);

  const statusSetter = useCallback(
    ({ maintenance = maintenanceStatus, error = errorStatus }) => {
      setStatus({ maintenance, error });
    },
    [maintenanceStatus, errorStatus]
  );

  return (
    <HeathStatusContext.Provider
      value={{
        maintenance: maintenanceStatus,
        error: errorStatus,
        setStatus: statusSetter
      }}
    >
      {children}
    </HeathStatusContext.Provider>
  );
}
