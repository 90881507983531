import { useState, useEffect } from 'react';
import { APP_SETTINGS } from 'services/App/App.constants';
import { useHealthStatus } from '../contexts/healthStatus';
import { useAuth } from '../contexts/auth';

export default function useInstitutions() {
  const [institutions, setInstitutions] = useState([]);

  const { accessToken, accessTokenInfo } = useAuth();
  const { setStatus } = useHealthStatus();

  useEffect(() => {
    if (!accessToken || !accessTokenInfo) {
      return;
    }

    const {
      guest: { country, lang },
      aud: [resourceServer]
    } = accessTokenInfo;

    fetch(
      `${resourceServer}/v8/api/web/presenter/institutions.json?locale=${lang}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'mt-app-name': 'mt-personal-web',
          'x-api-key': APP_SETTINGS.CLIENT_ID,
          'x-api-version': APP_SETTINGS.VERSION
        }
      }
    )
      .then(response => {
        if (response.status === 503) {
          setStatus({ maintenance: true });
          return;
        }

        if (response.status !== 200) {
          setStatus({ error: true });
          return;
        }

        response.json().then(body => {
          setInstitutions(
            body.institutions.filter(
              institution => country === institution.country
            )
          );
        });
      })
      .catch(error => {
        console.error(error);

        setStatus({ error: true });
        setInstitutions([]);
      });
  }, [accessToken, accessTokenInfo, setStatus]);

  return institutions;
}
