/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { translate, Interpolate } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import * as colors from 'style/colors';
import { ALL_INSTITUTION_TYPES_KEY } from 'services/InstitutionTypes.constants';

const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
  .filterDropdownToggle {
    min-height: 40px;
    border: 1px solid ${colors.borderDefault};
    border-radius: 4px;
    padding: 10px;
    padding-top: 9px;
    padding-bottom: 7px;
    margin-top: 10px;
    position: relative;
    display: block;
    .filteredInsitution {
      font-weight: 500;
    }
    .dropdownToggleButton {
      position: absolute;
      right: 1px;
      top: 2px;
      width: 40px;
      height: 38px;
      background-color: white;
      border: none;
      color: ${colors.primary};
      line-height: 40px;
      font-size: 14px;
    }
  }
  &.expanded {
    .filterDropdownToggle {
      border: 1px solid ${colors.primary};
    }
  }
  .filterDropdown {
    display: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid ${colors.greyMedium};
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    max-height: 300px;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    z-index: 1;

    .accountListItem {
      display: block;
      position: relative;
      padding: 5px 7px;
      font-size: 14px;
      border-top: 1px solid ${colors.greyMedium};
      &:last-child {
        border-bottom: 1px solid ${colors.greyMedium};
      }
      &:hover {
        background-color: ${colors.greyUltraLight};
      }
      .checkBox {
        position: absolute;
        right: 10px;
        top: 4px;
        line-height: 30px;
        font-weight: bold;
        color: ${colors.primary};
      }
    }
    .accountListGroupTitle {
      font-size: 12px;
      color: ${colors.greyMedium};
      margin-bottom: 4px;
      margin-top: 20px;
    }
  }
  &.expanded {
    border-color: blue;
    position: relative;
    .filterDropdown {
      display: block;
    }
  }
`;

class InstitutionsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterExpanded: false
    };
  }

  setFilter(institutionCategory) {
    const { onChange } = this.props;
    this.toggleFilter();
    onChange(institutionCategory.key);
  }

  toggleFilter() {
    const { filterExpanded } = this.state;
    this.setState({
      filterExpanded: !filterExpanded
    });
  }

  handleClickOutside() {
    const { filterExpanded } = this.state;
    if (filterExpanded) {
      this.toggleFilter();
    }
  }

  render() {
    const { t, selectedInstitution, institutionGroups } = this.props;
    const { filterExpanded } = this.state;
    return (
      <Wrapper className={`${filterExpanded ? 'expanded' : ''}`}>
        <div
          className="filterDropdownToggle"
          onClick={() => this.toggleFilter()}
        >
          <Interpolate
            i18nKey="institutionsFilter.filterBy"
            label={<span className="filteredInsitution">{selectedInstitution.label}</span>}
            useDangerouslySetInnerHTML
          />
          <button className="dropdownToggleButton"><i className="icon-down-arrow" /></button>
        </div>
        <div className="filterDropdown">
          <div>
            <div
              className="accountListItem"
              onClick={() => this.setFilter({ label: t('institutionTypes.ALL'), key: ALL_INSTITUTION_TYPES_KEY })}
            >
              { t('institutionTypes.ALL') }
              { selectedInstitution.type === ALL_INSTITUTION_TYPES_KEY &&
                <div className="checkBox"><i className="icon-selection-validation-tick" /></div>}
            </div>
          </div>
          { institutionGroups.map(group => (
            <div key={group.type}>
              <h4 className="accountListGroupTitle">{ t(`institutionTypes.${group.type}`)}</h4>
              { group.subTypes.map(subType => (
                <div
                  key={subType.key}
                  className="accountListItem"
                  onClick={() => this.setFilter(subType)}
                >
                  { subType.label }
                  { selectedInstitution.type === subType.key &&
                    <div className="checkBox"><i className="icon-selection-validation-tick" /></div>}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Wrapper>
    );
  }
}

InstitutionsFilter.propTypes = {
  t: PropTypes.func.isRequired,
  selectedInstitution: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  institutionGroups: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    subTypes: PropTypes.array
  })).isRequired,
  onChange: PropTypes.func.isRequired
};

const onClickOutsideHOC = onClickOutside(InstitutionsFilter);
const translateHOC = translate()(onClickOutsideHOC);

export default translateHOC;
