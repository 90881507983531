import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import receiptViewerModel from 'models/receiptViewer';
import Receipt from '../Receipt';
import ReceiptUploader from '../ReceiptUploader';

import locales from './locales.json';

import './styles.css';

// values come from CSS styles
// header height is total height of everything (dom elements) above this ReceiptContainer dom element
const HEADER_HEIGHT = 53 + 55 + 69 + 66;
const HEADER_HEIGHT_RESPONSIVE = HEADER_HEIGHT + 202 - 66;
const RESPONSIVE_WIDTH = 550;

const ReceiptContainer = inject('receiptsModel')(
  // listen to receipts updates
  observer(
    class ReceiptContainer extends React.Component {
      constructor(props) {
        super(props);

        this.mounted = false;

        this.state = {
          displayHeight: 0,
          isFullPage: true
        };
      }

      componentDidMount() {
        this.mounted = true;
        this.updateContainerAndReceiptDimension();

        window.addEventListener('resize', this.updateContainerAndReceiptDimension);
      }

      componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.updateContainerAndReceiptDimension);
      }

      updateContainerAndReceiptDimension = () => window.requestAnimationFrame(() => {
        if (!this.mounted) {
          return;
        }

        const height = window.innerWidth > RESPONSIVE_WIDTH ? HEADER_HEIGHT : HEADER_HEIGHT_RESPONSIVE;

        Receipt.CALCULATE_RECEIPT_DIMENSION();

        this.setState({
          displayHeight: window.innerHeight - height
        });
      })

      onClickReceipts = (receiptID) => {
        const { listType } = this.props;
        receiptViewerModel.open(receiptID, listType);
      }

      onSelectImage = (receiptID) => {
        const { receiptsModel, onImagesMarked } = this.props;
        const { attached } = receiptsModel;
        let selectedImages;
        if (attached.includes(receiptID)) {
          selectedImages = attached.filter(id => id !== receiptID);
        } else {
          selectedImages = [...attached];
          selectedImages.push(receiptID);
        }
        onImagesMarked(selectedImages);
      }

      renderImages(receiptIDs) {
        const { receiptsModel, attachedImages } = this.props;
        const { attached } = receiptsModel;
        return receiptIDs.map(receiptID => (
          <Receipt
            key={receiptID}
            id={receiptID}
            dimension={Receipt.RECEIPT_DIMENSION}
            onClick={this.onClickReceipts}
            isAttachable={!!attachedImages}
            isSelected={attached && attached.includes(receiptID)}
            onSelect={this.onSelectImage}
          />
        ));
      }

      renderListSection() {
        const { receiptsModel, listType } = this.props;
        const monthYearToReceiptIDs = receiptsModel.getListInfoByType(listType);

        return monthYearToReceiptIDs.map(({ dateYear, list }) => (
          <div className="receipts-container-section" key={dateYear}>
            <div className="receipts-container-date">{dateYear}</div>
            <div className="receipts-container-group">
              {this.renderImages(list)}
            </div>
          </div>
        ));
      }

      renderLoadMoreButton(translations) {
        const { isFullPage } = this.state;
        if (!isFullPage) {
          return null;
        }

        // display load more (next page) if current page already have full data
        return (
          <button
            className="receipts-container-load-more-button"
            onClick={this.loadMoreData}
          >
            {translations.loadMore}
          </button>
        );
      }

      renderEmptyReceiptsMessage(translations) {
        const { listType } = this.props;
        const translationsText = translations[`${listType}EmptyReceipts`];

        return (
          <div className="receipts-container-empty-receipts">
            <div className="receipts-container-empty-receipts-title">{translationsText.title}</div>
            <div>{translationsText.text}</div>
          </div>
        );
      }

      render() {
        if (this.mounted) {
          Receipt.CALCULATE_RECEIPT_DIMENSION();
        }

        const locale = window.localStorage.getItem('mt.locale');
        const language = locale.substring(0, 2);

        // translation texts
        const translations = locales[language] || {};

        const { receiptsModel, listType } = this.props;
        const receiptsCount = receiptsModel[listType].length;
        const haveReceipt = receiptsCount > 0;
        const { displayHeight } = this.state;

        return (
          <div
            className="receipts-container"
            style={{ height: `${displayHeight}px` }}
          >
            <ReceiptUploader />
            {!haveReceipt && this.renderEmptyReceiptsMessage(translations)}
            {haveReceipt && this.renderListSection()}
            {haveReceipt && this.renderLoadMoreButton(translations)}
          </div>
        );
      }

      loadMoreData = async () => {
        const { receiptsModel } = this.props;
        // if this is a full page, we flag it
        const isFullPage = await receiptsModel.load();

        this.setState({
          isFullPage
        });
      }
    }
  )
);

ReceiptContainer.propTypes = {
  listType: PropTypes.string,
  attachedImages: PropTypes.arrayOf(PropTypes.object)
};

export default ReceiptContainer;
