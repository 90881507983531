import styled from 'styled-components';

export default styled.button`
  background-color: #20c51b;
  color: white;
  border-radius: 100px;
  cursor: pointer;
  font-size: 18px;
  height: 48px;
  white-space: nowrap;
  min-height: 32px;
  min-width: 88px;
  border: 1px solid;
  padding: 5px 15px;
  pointer-events: auto;
`;
