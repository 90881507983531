import React from 'react';
import classnames from 'classnames';

import './styles.css';

export default class Spinner extends React.PureComponent {
  render() {
    const { className, color } = this.props;

    return (
      <div className={classnames('spinner', className)}>
        <svg className="spinner-icon" viewBox="0 0 32 32">
          <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            strokeWidth="3"
            stroke={color}
            opacity={0.6}
          />
          <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            strokeWidth="3"
            stroke={color}
            strokeDasharray={80}
            strokeDashoffset={60}
          />
        </svg>
      </div>
    );
  }
}

Spinner.defaultProps = {
  className: '',
  color: '#0bc1e6'
};
