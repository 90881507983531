import React from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import PropsTypes from 'prop-types';
import Spinner from 'components/Spinner';
import styled from 'styled-components';

import './styles.css';

import receiptsService from 'services/receipts';

const ImageWrapper = styled.div`
  .image-group {
    cursor: pointer;
    position: relative;
    z-index: 1;
    .icon-check {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 12px;
      padding-top: 1px;
      background-color: lightgray;
      border-radius: 20px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: white;
      transition: background-color 300ms ease;
      &.marked {
        background-color: #4097fa;
        &:hover {
          background-color: #59a5fb;
        }
      }
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
`;

const DIMENSION = 140;
const MARGIN = 10;

const RESPONSIVE_WIDTH = 900; // check Receipts/styles.css

const Receipt = inject('receiptsModel', 'imageCacheModel')(
  observer(
    class Receipt extends React.Component {
      static RECEIPT_DIMENSION = DIMENSION;

      // scale width according to space width available
      static CALCULATE_RECEIPT_DIMENSION() {
        const windowWidth = window.innerWidth;
        // check Receipts/styles.css
        // when screen less than responsive width, use 10px padding else 30px padding
        const containerPadding = windowWidth <= RESPONSIVE_WIDTH ? 10 : 30;
        const containerWidth = windowWidth - containerPadding * 2;
        const actualDimension = DIMENSION + MARGIN * 2;
        const receiptsCountPerRow = Math.floor(containerWidth / actualDimension);
        const totalReceiptsDimension = receiptsCountPerRow * actualDimension;

        Receipt.RECEIPT_DIMENSION = actualDimension;

        if (containerWidth > totalReceiptsDimension) {
          Receipt.RECEIPT_DIMENSION = Math.floor(containerWidth / receiptsCountPerRow) - MARGIN * 2;
        }
      }

      constructor(props) {
        super(props);

        this.mounted = false;
        this.uploadStatus = undefined;
        this.angular = null;
        const { isSelected } = this.props;
        this.state = {
          src: undefined,
          selected: isSelected
        };
      }

      async componentDidMount() {
        this.mounted = true;
        this.angular = window.angular.element(document.body.querySelector('*[ng-app]')).injector();
        const { id } = this.props;
        const src = await receiptsService.get.imageByID(id, true);

        if (this.mounted) {
          this.setState({ src });
        }
      }

      componentWillUnmount() {
        this.mounted = false;
      }

      viewReceipt = () => {
        const { id, onClick, isAttachable } = this.props;

        if (isAttachable) {
          this.viewOldReceipt();
          return;
        }

        if (onClick) {
          onClick(id);
        }
      }

      viewOldReceipt = () => {
        const { receiptsModel, id, imageCacheModel } = this.props;
        const receipt = receiptsModel.getByID(id);
        const imageCache = imageCacheModel.get(id);
        // method and logic from angular mt-web-modules
        const MtCloudSafePopupService = this.angular.get('MtCloudSafePopupService');
        MtCloudSafePopupService.open({
          id: receipt.imageID, // this image id is the actual image ID instead of the s3_key
          ...receipt,
          formatted: {
            url: imageCache,
            thumbnailUrl: imageCacheModel.get(receipt.thumbnailKey) || imageCache
          }
        }, false);
      }

      selectReceipt = (event) => {
        const { id, onSelect } = this.props;
        const { selected } = this.state;
        this.setState({ selected: !selected });
        onSelect(id);
        event.stopPropagation();
        event.preventDefault();
      }

      renderImage() {
        const { receiptsModel, id, isAttachable } = this.props;
        const { selected, src } = this.state;
        const receipt = receiptsModel.getByID(id);
        let degree = 0;
        if (receipt) {
          // eslint-disable-next-line prefer-destructuring
          degree = receipt.degree;
        }

        return (
          <ImageWrapper className="receipt-image-limiter">
            <div className="receipt-image-limiter image-group">
              <div className="receipt-image-background" />
              <img
                className="receipt-image"
                src={src}
                alt="Loading..."
                style={{ transform: `translate(-50%, -50%) rotate(${degree}deg)` }}
              />
              {isAttachable && <i className={`icon-check ${selected && 'marked'}`} onClick={this.selectReceipt} />}
            </div>
          </ImageWrapper>
        );
      }

      renderSpinner() {
        return (
          <>
            <div className="receipt-image-spinner-background" />
            <Spinner className="receipt-image-spinner" />
          </>
        );
      }

      getUploadStatusRef = (ref) => {
        this.uploadStatus = ref;
      }

      renderUploadStatus() {
        const { uploadStatus } = this.props;
        if (this.uploadStatus) {
          this.uploadStatus.style.width = `${uploadStatus * 100}%`;
        }

        return (
          <div className="receipt-upload-status" ref={this.getUploadStatusRef} />
        );
      }

      render() {
        const { dimension, uploadStatus } = this.props;
        const { src } = this.state;
        const receiptDimension = (dimension === Infinity) ? Receipt.dimension : dimension;

        return (
          <div
            className={classnames('receipt', { 'receipt-with-upload-status': uploadStatus })}
            style={{ width: receiptDimension, height: receiptDimension }}
            onClick={this.viewReceipt}
          >
            {src ? this.renderImage() : this.renderSpinner()}
            {uploadStatus !== undefined && this.renderUploadStatus()}
          </div>
        );
      }
    }
  )
);

export default Receipt;

Receipt.propTypes = {
  id: PropsTypes.string,
  dimension: PropsTypes.number,
  onClick: PropsTypes.func,
  uploadStatus: PropsTypes.number,
  receiptsModel: PropsTypes.object,
  isAttachable: PropsTypes.bool,
  isSelected: PropsTypes.bool,
  onSelect: PropsTypes.func
};
