import React, { useCallback, useState } from 'react';
import { useAuth } from '../contexts/auth';
import OnboardingInstitutions from './OnboardingInstitutions';
import useInstitutions from '../hooks/useInstitutions';
import { useHealthStatus } from '../contexts/healthStatus';
import { APP_SETTINGS } from '../services/App/App.constants';

// handle preselected institution and submit them to the resource server
export default function OnboardingInstitutionManager() {
  const [preselectInstitutions, setPreselectInstitutions] = useState({});

  const { setStatus } = useHealthStatus();
  const { accessToken, accessTokenInfo } = useAuth();
  const institutions = useInstitutions();

  const setInstitution = useCallback(
    institution => {
      const { aud: [resourceServer] } = accessTokenInfo;

      return fetch(`${resourceServer}/v8/api/credentials.json`, {
        method: 'POST',
        headers: {
          'mt-app-name': 'mt-personal-web',
          Authorization: `Bearer ${accessToken}`,
          'x-api-key': APP_SETTINGS.CLIENT_ID,
          'x-api-version': APP_SETTINGS.VERSION,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          institution_id: institution.id,
          key_values: {},
          uses_certificate: institution.certificate_required.toString()
        })
      }).then(response => {
        if (response.status === 503) {
          setStatus({ maintenance: true });
          return;
        }

        if (response.status >= 500 && response.status < 600) {
          setStatus({ error: true });
        }
      }).catch(error => {
        console.error(error);

        setStatus({ error: true });
      });
    },
    [accessToken, accessTokenInfo, setStatus]
  );

  const onSubmitInstitutions = useCallback(() => {
    Promise.all(
      Object.values(preselectInstitutions).map(institution =>
        setInstitution(institution)
      )
    ).then(() => {
      window.location.href = '/app/vault?open_groups=all';
    });
  }, [preselectInstitutions, setInstitution]);

  const onSelectInstitution = useCallback(
    ({ isActive, model }) => {
      if (isActive) {
        preselectInstitutions[model.id] = model;
      } else {
        delete preselectInstitutions[model.id];
      }

      setPreselectInstitutions({ ...preselectInstitutions });
    },
    [preselectInstitutions]
  );

  return (
    <OnboardingInstitutions
      country={accessTokenInfo.guest.country}
      institutions={institutions}
      preselectInstitutions={preselectInstitutions}
      onSelectInstitution={onSelectInstitution}
      onSubmitInstitutions={onSubmitInstitutions}
    />
  );
}
