import 'babel-polyfill';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { injectGlobal } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import i18n from './lib/i18next';
import App from './components/App';
import HealthStatusProvider from './contexts/healthStatus';
import AuthProvider from './contexts/auth';

import './services/legacyBackDoor';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  html {
    height: auto;
  }
`;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <HealthStatusProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </HealthStatusProvider>
    </Router>
  </I18nextProvider>,
  document.getElementById('app')
);
