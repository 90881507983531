import styled from 'styled-components';
import { DefaultWrapper } from 'components/Modal';
import * as colors from 'style/colors';

export default styled(DefaultWrapper)`
  .controls {
    .search, .filter {
      width: 100%;
      height: 42px;
      position: relative;

      .icon {
        position: absolute;
        color: ${colors.greyMediumDarker};
        font-size: 20px;
      }

      input {
        width: inherit;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .search {
      .icon {
        top: 11px;
        left: 12px;
      }
      input {
        padding-left: 42px;
        padding-right: 10px;
        padding-top: 1px;

        &:focus {
          border: 1px solid ${colors.primary};
        }
      }
    }
  }

  .buttonLink {
    color: ${colors.primary};
    padding: 0;
    text-decoration: underline;
    &:hover, &:active, &:visited {
      color: ${colors.primary};
    }
  }

  .institutionList {
    padding-top: 30px;
    margin-bottom: 2px;
    z-index: 0;

    &.isPreselected {
      margin-bottom: 68px;
    }
  }

  .selectedInstitutions {
    font-size: 16px;
  }
`;
