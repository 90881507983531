/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Spinner from 'components/Spinner';

import receiptsService from 'services/receipts';
import receiptViewerModel from 'models/receiptViewer';
import receiptsModel from 'models/receipts';
import './styles.css';

const STRIPE_SCROLL_SPEED = 15; // in pixel

const ImagesStripe = (
  observer(
    class ImagesStripe extends React.Component {
      constructor(props) {
        super(props);

        this.imagesStripe = undefined;
        this.maxScrollWidth = 0;
        this.isClickOnLeftButton = false;
        this.isClickOnRightButton = false;
        this.mounted = false;

        this.state = {
          thumbnails: {}
        };
      }

      componentDidMount() {
        this.mounted = true;
        this.updateStripeContent();

        window.addEventListener('resize', this.updateMaxScrollWidth);
      }

      componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.updateMaxScrollWidth);
      }

      updateMaxScrollWidth = () => window.requestAnimationFrame(() => {
        const { imagesStripe } = this;

        // return if there is no images stripe or component has been unmount
        if (!imagesStripe || !this.mounted) {
          return;
        }

        const { imageIDs } = receiptViewerModel;
        const totalWidth = imageIDs.length * (110 + 10 * 2); // receipt image width + receipt image padding
        const maxWidth = window.innerWidth - (30 + 20) * 2; // arrows width * stripe padding

        this.imagesStripe.style.width = `${totalWidth}px`;

        if (totalWidth > maxWidth) {
          this.imagesStripe.style.width = `${maxWidth}px`;
        }

        this.maxScrollWidth = imagesStripe.scrollWidth - imagesStripe.clientWidth;

        if (this.mounted) {
          this.forceUpdate();
        }
      })

      onSelectStripImage = (imageID) => {
        receiptViewerModel.imageID = imageID;
      }

      renderStripeContentImage = (imageID, src) => {
        const { degree } = receiptsModel.getByID(imageID);

        return (
          <img
            name={imageID}
            className="receipt-viewer-images-stripe-image-content"
            src={src}
            alt="Temporary Unavailable"
            style={{ transform: `translate(-50%, -50%) rotate(${degree}deg)` }}
          />
        );
      };

      renderStripeContentSpinner() {
        return (<Spinner className="receipt-viewer-images-stripe-image-content" />);
      }

      renderStripeContent(imageID) {
        const { thumbnails } = this.state;
        const className = classnames(
          'receipt-viewer-images-stripe-image',
          { 'receipt-viewer-images-stripe-image-active': imageID === receiptViewerModel.imageID }
        );

        const src = thumbnails[imageID];

        return (
          <div className={className} key={imageID} onClick={src && (() => this.onSelectStripImage(imageID))}>
            {src ? this.renderStripeContentImage(imageID, src) : this.renderStripeContentSpinner()}
          </div>
        );
      }

      async updateStripeContent() {
        const { imageIDs } = receiptViewerModel;
        const loadImages = imageIDs.map((imageID) => {
          return receiptsService.get.imageByID(imageID, true);
        });
        const images = await Promise.all(loadImages);

        const thumbnails = {};

        images.forEach((base64, index) => {
          thumbnails[imageIDs[index]] = base64;
        });

        if (this.mounted) {
          this.setState({ thumbnails });
        }
      }

      getContent = (ref) => {
        if (!ref) {
          return;
        }

        this.imagesStripe = ref;

        ref.addEventListener('scroll', () => window.requestAnimationFrame(
          () => this.updateMaxScrollWidth()
        ));

        this.updateMaxScrollWidth();
      }

      canScrollLeft() {
        const status = this.imagesStripe ? this.imagesStripe.scrollLeft > 0 : false;

        if (!status) {
          this.isClickOnLeftButton = false;
        }

        return status;
      }

      canScrollRight() {
        const status = this.imagesStripe ? this.imagesStripe.scrollLeft < this.maxScrollWidth : false;

        if (!status) {
          this.isClickOnRightButton = false;
        }

        return status;
      }

      scrollLeft = () => {
        if (!this.isClickOnLeftButton || !this.canScrollLeft()) {
          return;
        }

        const scrollLeft = this.imagesStripe.scrollLeft - STRIPE_SCROLL_SPEED;
        this.imagesStripe.scrollLeft = Math.max(0, scrollLeft);
      }

      scrollRight = () => {
        if (!this.isClickOnRightButton || !this.canScrollRight()) {
          return;
        }

        const scrollLeft = this.imagesStripe.scrollLeft + STRIPE_SCROLL_SPEED;
        this.imagesStripe.scrollLeft = Math.min(this.maxScrollWidth, scrollLeft);
      }

      animateScrollLeft() {
        window.requestAnimationFrame(() => {
          if (!this.clickOnLeftButton) {
            return;
          }

          this.scrollLeft();
          this.animateScrollLeft();

          if (this.mounted) {
            this.forceUpdate();
          }
        });
      }

      animateScrollRight() {
        window.requestAnimationFrame(() => {
          if (!this.isClickOnRightButton) {
            return;
          }

          this.scrollRight();
          this.animateScrollRight();

          if (this.mounted) {
            this.forceUpdate();
          }
        });
      }

      clickOnLeftButton = () => {
        this.isClickOnLeftButton = true;

        this.animateScrollLeft();
      }

      clickOnRightButton = () => {
        this.isClickOnRightButton = true;

        this.animateScrollRight();
      }

      letGoLeftButton = () => { this.isClickOnLeftButton = false; }

      letGoRightButton = () => { this.isClickOnRightButton = false; }

      renderLeftButton() {
        const { imageIDs } = receiptViewerModel;

        return imageIDs.length > 1 && this.canScrollLeft() ? (
          <div
            className="receipt-viewer-images-stripe-arrow"
            onTouchStart={this.clickOnLeftButton}
            onMouseDown={this.clickOnLeftButton}
            onTouchEnd={this.letGoLeftButton}
            onMouseUp={this.letGoLeftButton}
            onMouseOut={this.letGoLeftButton}
          >
            <div className="receipt-viewer-images-stripe-arrow-icon receipt-viewer-images-stripe-left-arrow" />
          </div>
        ) :
          (<div className="receipt-viewer-images-stripe-arrow" />);
      }

      renderRightButton() {
        const { imageIDs } = receiptViewerModel;

        return imageIDs.length > 1 && this.canScrollRight() ? (
          <div
            className="receipt-viewer-images-stripe-arrow"
            onTouchStart={this.clickOnRightButton}
            onMouseDown={this.clickOnRightButton}
            onTouchEnd={this.letGoRightButton}
            onMouseUp={this.letGoRightButton}
            onMouseOut={this.letGoRightButton}
          >
            <div className="receipt-viewer-images-stripe-arrow-icon receipt-viewer-images-stripe-right-arrow" />
          </div>
        ) :
          (<div className="receipt-viewer-images-stripe-arrow" />);
      }

      render() {
        const { imageIDs } = receiptViewerModel;
        const content = imageIDs.map(imageID => this.renderStripeContent(imageID));

        return (
          <div className="receipt-viewer-images-stripe">
            {this.renderLeftButton()}
            <div className="receipt-viewer-images-stripe-images" ref={this.getContent}>
              {content}
            </div>
            {this.renderRightButton()}
          </div>
        );
      }
    }
  )
);

export default ImagesStripe;
